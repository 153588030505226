import {
  SpendBankTransaction,
  SpendBankTransactionDetail,
  SpendLegacyTransaction,
  TransactionNotes,
} from "graphql/generated";
import { FormatMoney } from "helpers/format-money";
import { getTransactionType, getPaymentMethod } from "helpers/transaction";
import TableRowLabelValue, {
  TableValueStyle,
} from "shared-components/table-row-label-value";

type TransactionDetailsCardProps = {
  transaction:
    | SpendBankTransaction
    | SpendBankTransactionDetail
    | SpendLegacyTransaction;
  notes: TransactionNotes[];
  perspectiveId?: string;
};

function TransactionDetailsCard({
  transaction,
  notes,
  perspectiveId,
}: TransactionDetailsCardProps) {
  let style = "flex flex-col lg:table-row";
  let transactionType = getTransactionType(transaction);
  let allowedTransactionTypes: string[] = ["Invoice Refund", "Invoice Payment"];
  return (
    <div className="mr-auto">
      <p className="font-base font-medium mb-4">Transactions Details</p>
      <table className="w-full border-spacing-y-2 border-separate">
        <tbody>
          <TableRowLabelValue
            label="Status"
            value={transaction.status?.toLocaleLowerCase()}
            valueStyle={TableValueStyle.BADGE}
          />
          {false && (
            <TableRowLabelValue label="Fail Reason" value={undefined} />
          )}
          <TableRowLabelValue
            label="Transaction Type"
            value={transactionType}
            className="capitalize"
            customContainerStyle={style}
          />
          <TableRowLabelValue
            label="Description"
            value={
              transaction.metadata?.summary || transaction.metadata?.description
            }
            customContainerStyle={style}
          />
          <TableRowLabelValue
            label="Transaction ID"
            value={transaction?.id}
            customContainerStyle={style}
          />
          {allowedTransactionTypes.includes(transactionType) && (
            <TableRowLabelValue
              label="Payment Method"
              value={getPaymentMethod(
                transaction.metadata?.processor ?? "",
                transactionType
              )}
              className="capitalize"
              customContainerStyle={style}
            />
          )}
          <TableRowLabelValue
            label="Notes"
            value={notes.at(0)?.content}
            customContainerStyle={`${style} mb-4`}
          />
          <TableRowLabelValue
            label="Total Amount"
            value={FormatMoney(transaction?.amount ?? 0)}
          />
          {transaction.metadata?.originalTransaction && (
            <TableRowLabelValue
              label="Original Tran."
              value={transaction.metadata.originalTransaction || ""}
            />
          )}
          {transaction.metadata?.returnedTransaction && (
            <TableRowLabelValue
              label="Returned Tran."
              value={transaction.metadata.returnedTransaction || ""}
            />
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TransactionDetailsCard;
