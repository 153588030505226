import { useSplitClient } from "@splitsoftware/splitio-react";

export type SplitIOType = {
  isReady: Promise<void> | undefined;
  getTreatments: any;
  getTreatment: (treatment: string, attributes?: any) => string;
  isTreatmentOn: (treatment: string, attributes?: any) => boolean;
};
export default function useSplitIO(
  programId?: string | null,
  userId?: string | null
) {
  const client = useSplitClient().client;
  const getTreatment = (treatment: string, attributes?: any): string => {
    if (attributes == null) {
      attributes = {};
    }
    if (programId) {
      attributes["organizationId"] = programId;
    }
    if (userId) {
      attributes["spendId"] = userId;
    }
    return !!client
      ? client.getTreatments([treatment], attributes)[treatment]
      : "control";
  };
  const isTreatmentOn = (treatment: string, attributes?: any): boolean => {
    if (attributes == null) {
      attributes = {};
    }
    if (programId) {
      attributes["organizationId"] = programId;
    }
    if (userId) {
      attributes["spendId"] = userId;
    }
    return !!client
      ? client.getTreatments([treatment], attributes)[treatment] === "on"
      : false;
  };

  const getTreatments = (desiredSplits: string[], attributes?: any) => {
    if (attributes == null) {
      attributes = {};
    }
    if (programId) {
      attributes["organizationId"] = programId;
    }
    if (userId) {
      attributes["spendId"] = userId;
    }
    return client
      ? {
          isReady: client.ready(),
          treatments: client.getTreatments(desiredSplits, attributes) as any,
        }
      : { isReady: new Promise<void>(() => true), treatments: {} as any };
  };
  return {
    isReady: client?.ready(),
    getTreatment,
    getTreatments,
    isTreatmentOn,
  };
}
