import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  useMutation,
  useQuery,
} from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import UserContext from "context/user-context";
import cuid from "cuid";
import {
  Exact,
  PaymentsApiCustomerMutation,
  SpendBankAccount,
  SpendGroup,
  SpendInvoice,
  SpendMemo,
  useSpendPaymentsCardDetachMutation,
} from "graphql/generated";
import { USER_BANK_ACCOUNTS } from "graphql/queries/user";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import useModal from "hooks/use-modal";
import useToast from "hooks/use-toast";
import LabelValue from "pages/groups/collections/label-value";
import { useContext, useEffect, useState } from "react";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import LinkBank, {
  getRefetchQueries,
} from "shared-components/banking/link-bank";
import Divider from "shared-components/divider";
import DotProgress from "shared-components/dot-progress";
import { calcTotalPlusFee } from "shared-components/modal/make-payment/make-payment-helper";
import { SubHeaderView } from "shared-components/modal/make-payment/sub-header-view";
import SwitchRole from "shared-components/modal/switch-role";
import Spinner, { SpinnerContainer } from "shared-components/spinner";
import { OffSessionPaymentComponent } from "shared-components/payment-component/off-session-payment-component";
import ToastMessage from "shared-components/toast-message";
import { ReactComponent as NoDocumentIcon } from "assets/noDocument.svg";
import {
  SnapBadge,
  SnapButton,
  SnapRadioButton,
  SnapSelectMenu,
  SnapTable,
} from "suit";
import { PaymentTimingValue } from "../../types/invoice";
import { DELETE_USER_PLAID_ACCESS } from "graphql/mutations/plaid";
import { extractBankLabel } from "helpers/banking";
import { SnapSelectMenuCustomEvent } from "@snap-mobile/snap-ui/dist/types/components";
type PaymentsApiCustomerMutationMethod = (
  options?:
    | MutationFunctionOptions<
        PaymentsApiCustomerMutation,
        Exact<{
          [key: string]: never;
        }>,
        DefaultContext,
        ApolloCache<any>
      >
    | undefined
) => Promise<FetchResult<PaymentsApiCustomerMutation>>;
type ReviewInvoicesProps = {
  paymentTiming: PaymentTimingValue | undefined;
  setPaymentTiming: React.Dispatch<
    React.SetStateAction<PaymentTimingValue | undefined>
  >;
  group: SpendGroup;
  invoices: SpendInvoice[];
  invoicesLoading: boolean;
  playerName: string;
  handleUpdateInvoice: (updatedInvoice: SpendInvoice, idx: number) => void;
  selectedPaymentTypeOption: "Pay by: Card" | "Pay by: Bank" | undefined;
  setSelectedPaymentTypeOption: React.Dispatch<
    React.SetStateAction<"Pay by: Card" | "Pay by: Bank" | undefined>
  >;
  setSelectedBankAccount: React.Dispatch<
    React.SetStateAction<SpendBankAccount | undefined>
  >;
  optInList: string[];
  setOptInList: React.Dispatch<React.SetStateAction<string[]>>;
  accountId: string;
  paymentMethods: any[];
  email: string;
  getPaymentMethods: PaymentsApiCustomerMutationMethod;
  paymentMethodCreate: () => void;
  widgetOpen: boolean;
  dotState: number;
  dotMarker: number;
  showDiscount: boolean;
  seasonName?: string;
  hasCreditsApplied?: boolean;
};

function ReviewInvoices({
  paymentTiming,
  setPaymentTiming,
  group,
  invoices,
  invoicesLoading,
  playerName,
  handleUpdateInvoice,
  selectedPaymentTypeOption,
  setSelectedPaymentTypeOption,
  setSelectedBankAccount,
  optInList,
  setOptInList,
  accountId,
  paymentMethods,
  paymentMethodCreate,
  getPaymentMethods,
  widgetOpen,
  dotState,
  dotMarker,
  showDiscount,
  seasonName,
  hasCreditsApplied,
}: ReviewInvoicesProps) {
  const User = useContext(UserContext);
  const roles = User?.getAllRoles();
  const [deletePaymentMethod, { data, loading }] =
    useSpendPaymentsCardDetachMutation();
  const [deletePlaidLink] = useMutation(DELETE_USER_PLAID_ACCESS, {
    refetchQueries: getRefetchQueries("user"),
  });
  const { data: accountsData, loading: loadingAccountsData } =
    useQuery(USER_BANK_ACCOUNTS);
  const ActiveUser = useContext(UserContext);
  const extPayId = cuid();
  const { isOpen: roleSwitchOpen, toggle: roleSwitchToggle } = useModal();
  const { isToastOpen: toastOpen, ...toast } = useToast();
  const payByBank = selectedPaymentTypeOption === "Pay by: Bank";
  const payByCard = selectedPaymentTypeOption === "Pay by: Card";
  const [bankAccounts, setBankAccounts] = useState<SpendBankAccount[]>([]);
  const [bankOptions, setBankOptions] = useState<SnapSelectMenuOption[]>([]);
  const [accountStatus, setAccountStatus] = useState("");
  const [plaidConfig, setPlaidConfig] = useState<PlaidLinkOptions>({
    onSuccess: (public_token, metadata) => {},
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: "",
  });

  const { open, ready } = usePlaidLink(plaidConfig);
  const openPlaid = () => {
    if (ready) {
      open();
    }
  };
  useEffect(() => {
    if (!loading && data && getPaymentMethods) {
      getPaymentMethods();
    }
  }, [data, getPaymentMethods, loading]);
  useEffect(() => {
    if (!loadingAccountsData && accountsData?.spendUserBankAccounts) {
      const tempAccounts = [
        ...accountsData.spendUserBankAccounts.externalAccounts,
      ];
      setAccountStatus(accountsData.spendUserBankAccounts.status);
      setBankAccounts(tempAccounts);
      setBankOptions(
        tempAccounts.map((tAccount, idx) => ({
          name: extractBankLabel(tAccount),
          selected: idx === 0,
        }))
      );
      setSelectedBankAccount(
        tempAccounts.length > 0 ? tempAccounts[0] : undefined
      );
    }
  }, [loadingAccountsData, accountsData, accountId, setSelectedBankAccount]);

  const hasInvoiceToPay =
    invoices.length > 0 &&
    (invoices.some((invoice) => !invoice.isOptional) || optInList.length > 0);

  const handleCreditAppliedToInvoice = (memos: SpendMemo[]) => {
    return memos.reduce((acc, memo) => acc + memo.creditApplied, 0);
  };
  const handleSelectMenuUpdated = (
    e: SnapSelectMenuCustomEvent<SnapSelectMenuOption[]>,
    invoice: SpendInvoice,
    idx: number
  ) => {
    let tempInvoice = { ...invoice };
    let selectedItem = e.detail.find((e) => e.selected);
    if (selectedItem) {
      switch (selectedItem.name) {
        case "Opt-In":
          tempInvoice.optedIn = true;
          if (invoice.id) {
            const tempOptInList = [...optInList, invoice.id];
            setOptInList(tempOptInList);
          }

          break;
        case "Opt-Out":
          tempInvoice.optedIn = false;
          if (invoice.id) {
            const idx = optInList.indexOf(invoice.id ?? "");
            idx > -1 && optInList.splice(idx, 1);
            setOptInList(optInList);
          }
          break;
      }
      handleUpdateInvoice(tempInvoice, idx);
    }
  };
  return (
    <div>
      {toastOpen && (
        <ToastMessage
          message={toast.message}
          isToastOpen={toastOpen}
          toggleToast={toast.toggleToast}
          type={toast.type}
        />
      )}
      {roles && roles?.length > 1 && (
        <SnapButton
          variant="primary"
          className="flex justify-end"
          onClick={roleSwitchToggle}
        >
          Switch Role
        </SnapButton>
      )}
      <DotProgress state={dotState} markerCount={dotMarker} />
      <h2 className="text-xl lg:text-2xl font-semibold">Review Invoices</h2>
      <div className="flex mt-4 lg:w-[30%]">
        <div className="mr-auto">
          <p>Group</p>
          <p>Season</p>
          <p>Participant</p>
        </div>
        <div className="flex flex-col items-end lg:items-start">
          <p>{group.name}</p>
          <p>{seasonName}</p>
          <p>{playerName}</p>
        </div>
      </div>
      <Divider isVisibleOnMobile />
      <p className="mt-4 font-medium lg:text-lg lg:font-semibold">Invoices</p>
      {invoicesLoading ? (
        <Spinner className="lg:hidden" size="small" />
      ) : invoices ? (
        invoices.map((invoice, idx) => {
          return (
            <div
              key={invoice.id + `${idx}`}
              className="lg:hidden border border-gray-200 rounded-lg p-4 mt-4"
            >
              <p className="font-medium break-all">{invoice.description}</p>
              <Divider isVisibleOnMobile />
              <LabelValue
                label={"Due Date"}
                value={FormatDate(
                  invoice.dueDate ?? "",
                  DateFormatSupported.Words
                )}
                labelColor={"text-gray-500"}
                valueColor={""}
                className="mt-2"
              />
              {hasCreditsApplied && (
                <LabelValue
                  label={"Original Amount"}
                  value={FormatMoney(
                    handleCreditAppliedToInvoice(invoice.creditMemos ?? []) +
                      invoice.balanceDue!
                  )}
                  labelColor={"text-gray-500"}
                  valueColor={""}
                  className="mt-2"
                />
              )}
              {hasCreditsApplied && (
                <LabelValue
                  label={"Credit Applied"}
                  value={FormatMoney(
                    handleCreditAppliedToInvoice(invoice.creditMemos ?? [])
                  )}
                  labelColor={"text-gray-500"}
                  valueColor={""}
                  className="mt-2"
                />
              )}
              <LabelValue
                label={"Pay By Bank"}
                value={FormatMoney(
                  calcTotalPlusFee(
                    invoice.balanceDue ?? 0,
                    group?.organizationFees?.achPercent ?? 0,
                    group?.organizationFees?.achBaseFee ?? 0
                  )
                )}
                labelColor={"text-gray-500"}
                valueColor={""}
                className="mt-2"
              />
              <LabelValue
                label={"Pay By Card"}
                value={FormatMoney(
                  calcTotalPlusFee(
                    invoice.balanceDue ?? 0,
                    group?.organizationFees?.cardPercent ?? 0,
                    group?.organizationFees?.cardBaseFee ?? 0
                  )
                )}
                labelColor={"text-gray-500"}
                valueColor={""}
                className="mt-2"
              />
              {invoice.isOptional ? (
                <>
                  <Divider isVisibleOnMobile />
                  <SnapSelectMenu
                    label="Status"
                    className="mt-4"
                    selectMenuOptions={[
                      { name: "Opt-In", selected: invoice.optedIn ?? false },
                      { name: "Opt-Out", selected: !invoice.optedIn ?? false },
                    ]}
                    onSnap-select-menu-updated={(e) => {
                      handleSelectMenuUpdated(e, invoice, idx);
                    }}
                  />
                </>
              ) : (
                <div className="flex justify-between mt-2">
                  <p className="text-gray-500 font-bold text-sm">Status</p>
                  <div className="flex items-center">
                    <SnapBadge>Required</SnapBadge>
                  </div>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <span className="lg:hidden">
          <NoDocumentIcon />
          <span>No Invoice Due</span>
        </span>
      )}
      <SnapTable>
        <table className="ui celled hidden lg:table">
          <thead>
            <tr>
              <th className="w-[25%]">Description</th>
              <th>Due Date</th>
              {hasCreditsApplied && <th>Original Amount</th>}
              {hasCreditsApplied && <th>Credit Applied</th>}
              <th>Pay By Bank</th>
              <th>Pay By Card</th>
              <th align="right">Status</th>
            </tr>
          </thead>
          <tbody>
            {invoicesLoading ? (
              <SpinnerContainer
                loading={invoicesLoading}
                inTable={true}
                colSpan={5}
              />
            ) : (
              invoices.map((invoice, idx) => {
                return (
                  <tr key={invoice.id + `${idx}`}>
                    <td className="break-all py-4">{invoice.description}</td>
                    <td>
                      {FormatDate(
                        invoice.dueDate ?? "",
                        DateFormatSupported.Words
                      )}
                    </td>
                    {hasCreditsApplied && (
                      <td>
                        {FormatMoney(
                          handleCreditAppliedToInvoice(
                            invoice.creditMemos ?? []
                          ) + invoice.balanceDue!
                        )}
                      </td>
                    )}
                    {hasCreditsApplied && (
                      <td>
                        {FormatMoney(
                          handleCreditAppliedToInvoice(
                            invoice.creditMemos ?? []
                          )
                        )}
                      </td>
                    )}
                    <td>
                      {FormatMoney(
                        calcTotalPlusFee(
                          invoice.balanceDue ?? 0,
                          group?.organizationFees?.achPercent ?? 0,
                          group?.organizationFees?.achBaseFee ?? 0
                        )
                      )}
                    </td>
                    <td>
                      {FormatMoney(
                        calcTotalPlusFee(
                          invoice.balanceDue ?? 0,
                          group?.organizationFees?.cardPercent ?? 0,
                          group?.organizationFees?.cardBaseFee ?? 0
                        )
                      )}
                    </td>
                    <td align="right" className=" w-28">
                      {invoice.isOptional ? (
                        <SnapSelectMenu
                          modalTitle={
                            optInList.includes(invoice?.id || "")
                              ? "Opt-In"
                              : "Opt-Out"
                          }
                          selectMenuOptions={[
                            {
                              name: "Opt-In",
                              value: "Opt-In",
                              selected: optInList.includes(invoice?.id || ""),
                            },
                            {
                              name: "Opt-Out",
                              value: "Opt-Out",
                              selected: !optInList.includes(invoice?.id || ""),
                            },
                          ]}
                          onSnap-select-menu-updated={(e) => {
                            handleSelectMenuUpdated(e, invoice, idx);
                          }}
                        />
                      ) : (
                        <SnapBadge className="flex justify-end">
                          Required
                        </SnapBadge>
                      )}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </SnapTable>
      {!invoicesLoading && invoices.length === 0 && (
        <div className="lg:flex items-center flex-col hidden">
          <NoDocumentIcon className="w-[170px] h-[170px] my-1" />
          <h3 className="">No Invoice Due</h3>
        </div>
      )}
      <Divider isVisibleOnMobile className="mt-4 lg:mt-12" />
      <p className="mt-6 text-lg font-semibold">
        {paymentTiming !== "All" ? "Default " : ""}Payment Method
      </p>
      <SnapSelectMenu
        className="lg:w-[30%]"
        placeholder="- Select Payment Method -"
        selectMenuOptions={[
          {
            name: "Pay by: Card",
            selected: selectedPaymentTypeOption === "Pay by: Card",
          },
          {
            name: "Pay by: Bank",
            selected: selectedPaymentTypeOption === "Pay by: Bank",
          },
        ]}
        onSnap-select-menu-updated={(e) => {
          const op = e.detail.find((option) => option.selected)?.name;
          if (!op) {
            setSelectedPaymentTypeOption(undefined);
          } else {
            setSelectedPaymentTypeOption(
              op === "Pay by: Card" ? "Pay by: Card" : "Pay by: Bank"
            );
          }
        }}
      />
      {selectedPaymentTypeOption && (
        <SubHeaderView
          text={"Choose Payment Method"}
          hasLink={payByBank}
          link="https://snap-mobile.zendesk.com/hc/en-us/articles/21870750668315-Troubleshooting-Adding-a-Payment-Method-Bank-Account"
          linkText="Review our support center article for assistance"
        />
      )}
      {payByCard && extPayId && (
        <>
          {!widgetOpen ? (
            paymentMethods.map((method, idx) => {
              return (
                <div key={`${method.identifier} ${idx}`} className="flex">
                  <p>{method.identifier}</p>
                  <p
                    className="pl-5 text-red-500 font-bold cursor-pointer"
                    onClick={() =>
                      deletePaymentMethod({
                        variables: {
                          input: {
                            paymentMethodId: method.id,
                          },
                        },
                      })
                    }
                  >
                    Delete
                  </p>
                </div>
              );
            })
          ) : (
            <OffSessionPaymentComponent
              returnUrl={`${document.baseURI}&externalId=${extPayId}`}
              payerDetails={{
                id: "",
                name: ActiveUser?.getName() ?? "",
                email: ActiveUser?.getEmail() ?? "",
              }}
              destination={accountId}
              externalPaymentId={extPayId}
              paymentMethodCreate={paymentMethodCreate}
            />
          )}
        </>
      )}
      {payByBank && bankAccounts.length > 0 ? (
        <div className="lg:flex justify-between lg:flex-col mt-2">
          <div className="pr-20 whitespace-nowrap text-xs font-semibold text-gray-500 self-center lg:self-start lg:mb-2">
            <p>Linked Bank</p>
          </div>
          <SnapSelectMenu
            placeholder="- Select Bank Account -"
            selectMenuOptions={bankOptions}
            onSnap-select-menu-updated={(e) => {
              const op = e.detail.find((option) => option.selected)?.name;
              if (!op) {
                setSelectedBankAccount(undefined);
                setBankOptions(e.detail);
              } else {
                const selectedAccount = bankAccounts.find(
                  (account) => op === extractBankLabel(account)
                );
                setSelectedBankAccount(selectedAccount);
                setBankOptions(e.detail);
              }
            }}
          />
        </div>
      ) : (
        payByBank && (
          <div className="lg:flex">
            <LinkBank
              openPlaid={openPlaid}
              setPlaidConfig={setPlaidConfig}
              toast={toast}
              type={"user"}
              labelText={
                accountStatus === "pending_manual_verification"
                  ? "Verify Account"
                  : "Add Account"
              }
              groupId={group.id}
            />
            {accountStatus === "pending_manual_verification" && (
              <p
                className="self-center lg:mt-4 mt-10 text-red-500 font-bold cursor-pointer"
                onClick={() => deletePlaidLink()}
              >
                Delete Account
              </p>
            )}
          </div>
        )
      )}
      <Divider isVisibleOnMobile />
      <p className="mt-4 text-lg font-semibold">Payment Timing</p>
      <SnapRadioButton
        label="Enroll in AutoPay"
        className="mt-4"
        checked={paymentTiming === "AutoPay"}
        onClick={() => setPaymentTiming("AutoPay")}
      />
      <ul className="list-disc pt-1 pb-2 ml-12 text-xs text-gray-500">
        <li className="mt-2">
          Get notified when someone posts a comment on a posting.
        </li>
        <li className="mt-2 mb-4">
          You can easily manage payments at any time from your snap! Spend
          account
        </li>
      </ul>
      {hasInvoiceToPay ? (
        <>
          <SnapRadioButton
            label="Pay All Now"
            checked={paymentTiming === "All"}
            onClick={() => setPaymentTiming("All")}
          />
          <ul className="list-disc pt-1 pb-2 ml-12 text-xs text-gray-500">
            <li className="mt-2">You will be charged now all invoices.</li>
            {showDiscount && (
              <li className="mt-2">
                Selecting this option will include a{" "}
                {FormatMoney(group.discountAmount)} discount.
              </li>
            )}
          </ul>
        </>
      ) : (
        <>
          <SnapRadioButton
            label="Make payments manually"
            checked={paymentTiming === "Manually"}
            onClick={() => setPaymentTiming("Manually")}
          />
          <ul className="list-disc pt-1 pb-2 ml-12 text-xs text-gray-500">
            <li className="mt-2">
              Get notified when new invoices are published.
            </li>
            <li className="mt-2">Authorize payments for each invoice.</li>
          </ul>
        </>
      )}
      <SwitchRole
        isOpen={roleSwitchOpen}
        toggle={roleSwitchToggle}
        currentRole={User?._session?.role}
        roles={roles}
      />
    </div>
  );
}

export default ReviewInvoices;
