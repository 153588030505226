import { useLazyQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import {
  InvoiceGuardianFilterEnum,
  Maybe,
  SpendGroupRoster,
  SpendGuardianInvoice,
  SpendGuardianInvoiceFilter,
  SpendInvoice,
  useSpendGuardianInvoicesLazyQuery,
} from "graphql/generated";
import { playerMenuOptions, teamMenuOptions } from "helpers/dummy-data";
import { resetFilters } from "helpers/reset-filters";
import { updateFilterCount } from "helpers/update-filter-count";
import { updateFilterSelection } from "helpers/update-filter-selection";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import DisplayFilterOptions from "shared-components/display-filter-options";
import Divider from "shared-components/divider";
import CustomModal, { BtnType } from "shared-components/modal";
import ShowingResults from "shared-components/showing-results";
import { SnapActionSheet, SnapButton, SnapIcon, SnapIndicator } from "suit";
import { Filters } from "types/filter-types";
import { GET_PARENTS_INVOICES } from "graphql/queries/group";
import UserContext from "context/user-context";
import useToast from "hooks/use-toast";
import ToastMessage from "shared-components/toast-message";
import DisplayContext from "context/display-context";
import FloatingActionBtn from "shared-components/floating-action-btn";
import {
  PaymentConfirmationModal,
  StripeResponse,
} from "shared-components/modal/payment-confirmation-modal";
import { ChangePaymentModal } from "shared-components/modal/change-payment/change-payment-modal";
import { MakePaymentModal } from "shared-components/modal/make-payment/make-payment-modal";
import ParentInvoiceItemV2 from "./Parent/parent-invoices-v2";
type PlayerInvoiceType = {
  team: Maybe<string> | undefined;
  player: Maybe<string> | undefined;
  invoices: Maybe<SpendInvoice | SpendGuardianInvoice>[] | undefined;
};

function ParentInvoices() {
  const { isOpen, toggle } = useModal();
  const Display = useContext(DisplayContext);
  const ActiveUser = useContext(UserContext);
  const [fabOpen, setFabOpen] = useState(false);
  const { isOpen: changePaymentOpen, toggle: changePaymentToggle } = useModal();
  // eslint-disable-next-line
  const [stripeResponse, setStripeState] = useState<StripeResponse>({
    redirect_status: "spend_init",
  });
  const [
    parentInvoicesQuery,
    { data: getGroupData, loading: loadingGroupData },
  ] = useLazyQuery(GET_PARENTS_INVOICES, {
    variables: {
      filterBy: "memberId",
      filterValue: ActiveUser?._session?.userId,
    },
  });
  const [guardianInvoicesQuery, { data: giData, loading: giLoading }] =
    useSpendGuardianInvoicesLazyQuery();

  useEffect(() => {
    guardianInvoicesQuery();
  }, [
    parentInvoicesQuery,
    guardianInvoicesQuery,
    ActiveUser?._session?.userId,
  ]);

  const [selectedFilterCount, setSelectedFilterCount] = useState(0);
  const [invoicesData, setInvoicesData] = useState<
    PlayerInvoiceType[] | undefined
  >(undefined);
  const [guardianInvoicesFiltered, setGuardianInvoicesFiltered] = useState<
    SpendGuardianInvoice[] | undefined
  >();
  const [parentFilters, setParentFilters] = useState<Filters[]>([
    {
      filterName: "Participants",
      type: "multi",
      filterOptions: playerMenuOptions,
      className: "mr-4",
    },
    {
      filterName: "Groups",
      type: "multi",
      filterOptions: teamMenuOptions,
    },
  ]);

  useEffect(() => {
    setDataLength(
      guardianInvoicesFiltered?.length || invoicesData?.length || 0
    );
  }, [guardianInvoicesFiltered, invoicesData]);
  const [dataLength, setDataLength] = useState(0);
  useEffect(() => {
    if (getGroupData && getGroupData?.spendGroupRosters) {
      let allInvoices = getGroupData?.spendGroupRosters.groupRosters.flatMap(
        (rosters: Maybe<SpendGroupRoster>) =>
          rosters?.invoices?.map((invoice) => invoice)
      );
      setInvoicesData(allInvoices);

      const uniquePlayerNames = new Map();
      const uniqueTeamNames = new Map();

      getGroupData?.spendGroupRosters?.groupRosters.forEach(
        (roster: SpendGroupRoster) => {
          if (roster?.roster?.name) {
            uniquePlayerNames.set(roster.roster.name, roster.roster.id);
          }
          if (roster.group?.name) {
            uniqueTeamNames.set(roster.group.name, roster.group.id);
          }
        }
      );
      let participantsFilter: Filters = parentFilters[0];
      let groupsFilters: Filters = parentFilters[1];

      if (!parentFilters[0].filterOptions?.length) {
        participantsFilter = {
          ...parentFilters[0],
          filterOptions: Array.from(uniquePlayerNames).map((options) => ({
            name: options[0],
            value: options[1],
            selected: false,
          })),
        };
      }

      if (!parentFilters[1].filterOptions?.length) {
        groupsFilters = {
          ...parentFilters[1],
          filterOptions: Array.from(uniqueTeamNames).map((options) => ({
            name: options[0],
            value: options[1],
            selected: false,
          })),
        };
      }

      setParentFilters([participantsFilter, groupsFilters]);
    }
    // eslint-disable-next-line
  }, [getGroupData, loadingGroupData]);

  useEffect(() => {
    if (giData && !giLoading) {
      const invoices = giData.spendGuardianInvoices?.invoices ?? [];
      setGuardianInvoicesFiltered(invoices);

      const uniquePlayerNames = new Map();
      const uniqueTeamNames = new Map();

      invoices.forEach((i: SpendGuardianInvoice) => {
        if (i.rosterName) {
          uniquePlayerNames.set(i.rosterName, i.rosterId);
        }
        if (i.groupName) {
          uniqueTeamNames.set(i.groupName, i.groupId);
        }
      });

      let participantsFilter: Filters = parentFilters[0];
      let groupsFilters: Filters = parentFilters[1];

      if (!parentFilters[0].filterOptions?.length) {
        participantsFilter = {
          ...parentFilters[0],
          filterOptions: Array.from(uniquePlayerNames).map((options) => ({
            name: options[0],
            value: options[1],
            selected: false,
          })),
        };
      }

      if (!parentFilters[1].filterOptions?.length) {
        groupsFilters = {
          ...parentFilters[1],
          filterOptions: Array.from(uniqueTeamNames).map((options) => ({
            name: options[0],
            value: options[1],
            selected: false,
          })),
        };
      }

      setParentFilters([participantsFilter, groupsFilters]);
    }
    // eslint-disable-next-line
  }, [giData, giLoading]);

  const handleFilters = (
    selectedFilterName: string,
    details: SnapSelectMenuOption[]
  ) => {
    const updatedFilters = updateFilterSelection(
      parentFilters,
      selectedFilterName,
      details
    );

    const invoiceFilters: SpendGuardianInvoiceFilter[] = [];

    updatedFilters[0].filterOptions?.forEach((option) => {
      if (option.selected) {
        invoiceFilters.push({
          by: InvoiceGuardianFilterEnum.RosterId,
          value: option.value ?? "",
        });
      }
    });
    updatedFilters[1].filterOptions?.forEach((option) => {
      if (option.selected) {
        invoiceFilters.push({
          by: InvoiceGuardianFilterEnum.GroupId,
          value: option.value ?? "",
        });
      }
    });

    guardianInvoicesQuery({
      variables: {
        filters: invoiceFilters,
      },
    });

    const activeFilterCount = updateFilterCount(updatedFilters);
    setSelectedFilterCount(activeFilterCount || 0);
    setParentFilters(updatedFilters);
  };

  const handleResetFilters = () => {
    const defaultFilters = resetFilters(parentFilters);
    setParentFilters(defaultFilters);
    setSelectedFilterCount(0);
    guardianInvoicesQuery();
  };

  const { isToastOpen, ...toast } = useToast(
    "Invoice change request has been sent",
    "success"
  );

  const btn1: BtnType = { text: "Apply Filters", onClick: toggle };

  return (
    <div className="card">
      {isToastOpen && (
        <ToastMessage
          message={toast.message}
          isToastOpen={isToastOpen}
          toggleToast={toast.toggleToast}
          type={toast.type}
        />
      )}

      <CustomModal isOpen={isOpen} toggle={toggle} title="Filters" btn1={btn1}>
        <div className="px-4 pt-8  flex flex-col bg-white mt-4 pb-6">
          <div className="flex">
            <div className="flex mr-auto text-sm">
              <p className="font-medium mr-1">Selected </p>
              <p className="font-semibold">{selectedFilterCount} filters</p>
            </div>
            <p
              className=" text-base font-bold text-blue-600 cursor-pointer"
              onClick={handleResetFilters}
            >
              Reset Filters
            </p>
          </div>
          <DisplayFilterOptions
            filters={parentFilters}
            isDate={false}
            handleFilters={handleFilters}
            handleResetFilters={handleResetFilters}
          />
        </div>
      </CustomModal>

      <div
        className="border rounded-xl pt-2 pb-1 px-4 flex lg:hidden"
        onClick={toggle}
      >
        <SnapIcon icon="filter-solid" size="xs" className="text-gray-500" />
        <p className={`pl-2 ${selectedFilterCount === 0 ? "mr-auto" : "mr-2"}`}>
          Filters
        </p>
        {selectedFilterCount >= 1 && (
          <SnapIndicator
            size="sm"
            color="gray"
            text={selectedFilterCount}
            className="mr-auto"
          />
        )}
        <SnapIcon
          icon="chevron-down-solid"
          size="md"
          className="text-gray-500"
        />
      </div>
      <div className="hidden lg:flex justify-between">
        <p className="text-lg font-semibold">Filters</p>
        <p
          className="font-bold text-blue-600 cursor-pointer"
          onClick={handleResetFilters}
        >
          Reset Filters
        </p>
      </div>
      <div className="lg:block hidden">
        <DisplayFilterOptions
          filters={parentFilters}
          isDate={false}
          handleFilters={handleFilters}
          handleResetFilters={handleResetFilters}
        />
      </div>
      <Divider />
      <p className="text-lg font-semibold mt-6 lg:mb-0 mb-4">Invoices</p>
      <ShowingResults
        totalNumOfResults={dataLength}
        numOfResultsBeingDisplayed={dataLength}
        startingNumOfResults={dataLength === 0 ? 0 : 1}
      />
      <ParentInvoiceItemV2
        playerDatas={guardianInvoicesFiltered}
        toggleToast={toast.toggleToast}
      />
      <FloatingActionBtn
        onClick={() => setFabOpen(true)}
        icon={"dots-horizontal-solid"}
      />
      {fabOpen && (
        <SnapActionSheet onClick={() => setFabOpen(false)}>
          <Divider isVisibleOnMobile className="mb-4" />
          <div className="mx-4">
            <SnapButton
              variant="primary"
              size="md"
              onClick={() => {
                setFabOpen(false);
                changePaymentToggle();
              }}
              fullWidth
              className="mb-4"
            >
              Change Payment Method
            </SnapButton>
            <SnapButton
              variant="primary"
              size="md"
              onClick={() => {
                setFabOpen(false);
                Display?.setMakePaymentDataModalOpen({ isOpen: true });
              }}
              fullWidth
              className="mb-4"
            >
              Make Payment
            </SnapButton>
            {stripeResponse.redirect_from != null &&
              ["spend_init", "success"].includes(
                stripeResponse.redirect_status
              ) && <PaymentConfirmationModal stripeResponse={stripeResponse} />}
          </div>
        </SnapActionSheet>
      )}

      <MakePaymentModal
        setFabOpen={setFabOpen}
        makePaymentModalOpen={Display?.makePaymentModalOpen}
        setMakePaymentModalOpen={Display?.setMakePaymentModalOpen}
        makePaymentDataModalOpen={Display?.makePaymentDataModalOpen}
        setMakePaymentDataModalOpen={Display?.setMakePaymentDataModalOpen}
      />

      {changePaymentOpen && (
        <ChangePaymentModal
          isOpen={changePaymentOpen}
          toggle={changePaymentToggle}
        />
      )}
      {/* {data && data?.spendInvoices && <LoadMoreBTT loadMore={handleLoadMore} />} */}
    </div>
  );
}

export default ParentInvoices;
