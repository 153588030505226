import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { SpendGroup, SpendOrganization } from "graphql/generated";
import React, { createContext } from "react";

const DisplayContext = createContext<
  | {
      selectedDropdownOption: DropdownMenuItem | undefined;
      setSelectedDropdownOption: React.Dispatch<
        React.SetStateAction<DropdownMenuItem | undefined>
      >;
      settingsSaved: boolean;
      setSettingsSaved: React.Dispatch<React.SetStateAction<boolean>>;
      makePaymentModalOpen: boolean;
      setMakePaymentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
      makePaymentDataModalOpen: { isOpen: boolean; groupRosterId?: string };
      setMakePaymentDataModalOpen: React.Dispatch<
        React.SetStateAction<{ isOpen: boolean; groupRosterId?: string }>
      >;
      isDepositCheckOpen: boolean;
      setIsDepositCheckOpen: React.Dispatch<React.SetStateAction<boolean>>;
      unitAccount: string;
      setSelectedUnitAccount: (
        account: string,
        sendToName: string,
        id: string,
        programName: string
      ) => void;
      sendTo: string;
      programName: string;
      isMobile: boolean;
      isDesktop: boolean;
      proGroupId: string;
      dropdownMenuOptions: DropdownMenuItem[];
      setDropdownMenuOptions: React.Dispatch<
        React.SetStateAction<DropdownMenuItem[]>
      >;
      setDropdownOptions: (
        program: SpendOrganization | undefined,
        groups: SpendGroup[] | undefined
      ) => void;
    }
  | undefined
>(undefined);
export default DisplayContext;
