import { useEffect, useState } from "react";
import Approvals from "./approvals";
import OrgSignupLink from "./org-signup-link";
import { useContextStrict } from "helpers/context-strict";
import SplitIOContext from "context/splitio-context";

function OrganizationSettings() {
  const Splits = useContextStrict(SplitIOContext);
  const [spe1288DualApproval, setSPE1288DualApproval] = useState(false);
  useEffect(() => {
    setSPE1288DualApproval(Splits.isTreatmentOn("SPE-1288-Dual-Approval"));
  }, [Splits]);

  return (
    <div className="wrapper">
      <OrgSignupLink />
      {spe1288DualApproval && <Approvals />}
    </div>
  );
}

export default OrganizationSettings;
