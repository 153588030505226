import { SpendBankTransaction } from "graphql/generated";
import FixedModal from "./fixed-modal";
import { useMutation } from "@apollo/client";
import { CANCEL_ORG_CHECK } from "graphql/mutations/organization";
import { CANCEL_GROUP_CHECK } from "graphql/mutations/group";
import { useEffect } from "react";
import useToast from "hooks/use-toast";
import { CANCEL_ACH_PAYMENT } from "graphql/mutations/payment";

type RequestCancelProps = {
  requestCancelOpen: boolean;
  requestCancelToggle: () => void;
  focusOnTransaction: SpendBankTransaction | undefined;
  type: "program" | "group";
};

function RequestCancel({
  requestCancelOpen,
  requestCancelToggle,
  focusOnTransaction,
  type,
}: RequestCancelProps) {
  const toast = useToast();

  const [cancelCheck, { loading, data, error }] = useMutation(
    type === "program" ? CANCEL_ORG_CHECK : CANCEL_GROUP_CHECK
  );

  const [
    cancelACH,
    { loading: loadingCancelAch, data: cancelACHData, error: cancelACHError },
  ] = useMutation(CANCEL_ACH_PAYMENT);

  useEffect(() => {
    if (!loading) {
      if (
        data &&
        (data.spendOrgnizationCheckCancel || data.spendGroupCheckCancel)
      ) {
        toast.setToast({
          message: "Check canceled successfully",
          type: "success",
        });
        requestCancelToggle();
      }

      if (error) {
        toast.setToast({
          message: `${error.message}`,
          type: "danger",
        });
        requestCancelToggle();
      }
    }
    // eslint-disable-next-line
  }, [loading, data]);

  useEffect(() => {
    if (!loadingCancelAch) {
      if (cancelACHData && cancelACHData.spendUserAchPaymentCancel) {
        toast.setToast({
          message: "Transfer canceled successfully",
          type: "success",
        });
        requestCancelToggle();
      }

      if (cancelACHError) {
        toast.setToast({
          message: `${cancelACHError.message}`,
          type: "danger",
        });
        requestCancelToggle();
      }
    }
    // eslint-disable-next-line
  }, [loadingCancelAch, cancelACHData]);

  return (
    <FixedModal
      isOpen={requestCancelOpen}
      toggle={requestCancelToggle}
      title={""}
      icon="exclamation-solid"
      iconColor="#FEE2E2"
      btn1={{
        text: "Yes",
        btnStyle: "danger",
        onClick: () => {
          const unitId = focusOnTransaction?.correlationId?.split("_")[1];
          if (focusOnTransaction?.transactionType === "checkPayment") {
            cancelCheck({
              variables: {
                checkId: unitId,
              },
            });
          } else if (focusOnTransaction?.transactionType === "ach") {
            cancelACH({
              variables: {
                id: unitId,
              },
            });
          }

          console.log(focusOnTransaction);
        },
      }}
      btn2={{
        text: "No",
        btnStyle: "tertiary",
        onClick: requestCancelToggle,
      }}
    >
      <div className="text-center">
        <p className="text-lg font-medium">Request Cancel</p>
        <p className="text-sm text-gray-500">
          Are you sure you want to cancel this transaction
        </p>
      </div>
    </FixedModal>
  );
}

export default RequestCancel;
