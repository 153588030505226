import { useMutation } from "@apollo/client";
import DragDrog from "assets/DragDrop.svg";
import { UPLOAD_ATTACHMENTS } from "graphql/mutations/transactions";
import { GET_ATTACHMENT_BY_PAYMENT_ID } from "graphql/queries/transactions";
import { createRef, useEffect, useRef, useState } from "react";
import { SnapIcon } from "suit";

type DragAndDropProps = {
  label: string;
  type: "document" | "image";
  description?: string | null;
  className?: string | null;
  setUploadedFileHasError?: React.Dispatch<React.SetStateAction<boolean>>;
  paymentId: string;
};

function DragAndDrop({
  label,
  type,
  description,
  className,
  setUploadedFileHasError,
  paymentId,
}: DragAndDropProps) {
  // eslint-disable-next-line
  const [dragging, setDragging] = useState(false);
  const [uploadAttachment] = useMutation(UPLOAD_ATTACHMENTS);

  const dropRef = createRef<HTMLInputElement>();
  const fileElement = useRef<HTMLInputElement | null>(null);
  const handlefileElementClick = (ev: React.MouseEvent) => {
    fileElement !== null && fileElement.current?.click();
  };
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };
  const handleDragOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files[0].size >= 3000000) {
        setUploadedFileHasError && setUploadedFileHasError(true);
      } else {
        uploadFile(e.dataTransfer.files[0]);
      }
    }
  };

  useEffect(() => {
    if (dropRef != null && dropRef.current) {
      let div = dropRef.current;
      div.addEventListener("dragenter", handleDragIn);
      div.addEventListener("dragleave", handleDragOut);
      div.addEventListener("dragover", handleDrag);
      div.addEventListener("drop", handleDrop);
    }
    // eslint-disable-next-line
  }, []);

  const handleFileChange = (event: any) => {
    if (event.target.files[0].size >= 3000000) {
      setUploadedFileHasError && setUploadedFileHasError(true);
    } else {
      uploadFile(event.target.files[0]);
    }
  };

  const uploadFile = (file: any) => {
    const filename = file.name;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      uploadAttachment({
        variables: {
          input: {
            content: reader.result
              ?.toString()
              .replace("data:image/jpeg;base64,", ""),
            name: filename,
            paymentId: paymentId,
          },
        },
        refetchQueries: [
          {
            query: GET_ATTACHMENT_BY_PAYMENT_ID,
            variables: { id: paymentId },
          },
        ],
        fetchPolicy: "network-only",
      });
    };
  };
  return (
    <div className={`flex flex-col bg-blue-50 ${className}`}>
      <p>{label}</p>
      <div
        ref={dropRef}
        className="border-2 border-dashed border-gray-200 flex flex-col justify-center py-3 rounded-lg cursor-pointer"
        onClick={(event) => handlefileElementClick(event)}
      >
        {type === "image" ? (
          <img
            src={DragDrog}
            className="h-14"
            alt="Logo for Drag and Drop Feature"
          />
        ) : (
          <SnapIcon
            icon={
              type === "document" ? "document-text-line" : "photograph-line"
            }
            color="gray"
            size="lg"
          />
        )}
        <p className="lg:flex justify-center text-sm font-medium hidden text-gray-800">
          Drag & Drop File or
          <span className="ml-2 font-bold text-blue-500">Browse</span>
        </p>
        <p className="text-sm font-bold lg:hidden text-blue-500 flex justify-center mt-2">
          Upload File
        </p>
        <input
          type="file"
          ref={fileElement}
          className="hidden"
          onChange={handleFileChange}
          accept="application/pdf,image/jpeg,image/png"
        />
        <p
          className={`${
            description ? "flex" : "hidden"
          } justify-center text-xs leading-4 font-normal text-gray-500`}
        >
          {description}
        </p>
      </div>
    </div>
  );
}
export default DragAndDrop;
