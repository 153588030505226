export const invoiceSortingOptions = [
  {
    name: "Description A - Z",
    text: "Description A - Z",
    value: "description:ASC",
  },
  {
    name: "Description Z - A",
    text: "Description Z - A",
    value: "description:DESC",
  },
  {
    name: "Status A - Z",
    text: "Status A - Z",
    value: "status:ASC",
  },
  {
    name: "Status Z - A",
    text: "Status Z - A",
    value: "status:DESC",
  },
  {
    name: "Due Date Earliest - Latest",
    text: "Due Date Earliest - Latest",
    value: "dueDate:ASC",
  },
  {
    name: "Due Date Latest - Earliest",
    text: "Due Date Latest - Earliest",
    value: "dueDate:DESC",
  },
  {
    name: "Invoice Amount Lowest - Highest",
    text: "Invoice Amount Lowest - Highest",
    value: "amount:ASC",
  },
  {
    name: "Invoice Amount Highest - Lowest",
    text: "Invoice Amount Highest - Lowest",
    value: "amount:DESC",
  },
  {
    name: "Balance Due Lowest - Highest",
    text: "Balance Due Lowest - Highest",
    value: "balanceDue:ASC",
  },
  {
    name: "Balance Due Highest - Lowest",
    text: "Balance Due Highest - Lowest",
    value: "balanceDue:DESC",
  },
  {
    name: "Group A - Z",
    text: "Group A - Z",
    value: "groupName:ASC",
  },
  {
    name: "Group Z - A",
    text: "Group Z - A",
    value: "groupName:DESC",
  },
  {
    name: "Season A - Z",
    text: "Season A - Z",
    value: "seasonName:ASC",
  },
  {
    name: "Season Z - A",
    text: "Season Z - A",
    value: "seasonName:DESC",
  },
];
