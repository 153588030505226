import { useMutation } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import ProgramContext from "context/program-context";
import SplitIOContext from "context/splitio-context";
import ToastContext from "context/toast-context";
import { CREATE_INVOICE } from "graphql/mutations/invoice";
import { FormatMoney, ParseMoney } from "helpers/format-money";
import { useContext, useEffect, useState } from "react";
import InputMask from "shared-components/input-mask";
import CustomModal, { BtnState } from "shared-components/modal";
import { calcTotalPlusFee } from "shared-components/modal/make-payment/make-payment-helper";
import { SnapCheckboxButton, SnapInput, SnapSelectMenu } from "suit";
import { parentAddInvoicesErrors } from "types/errors";
import { ParticipantDetailType } from "..";
import {
  CategoryFilterEnum,
  useSpendCategoriesAddInvoiceLazyQuery,
} from "graphql/generated";
import DatePicker from "shared-components/date-picker";
import { isNullOrEmpty } from "helpers/null-or-empty";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";
type AddInvoiceProps = {
  isOpen: boolean;
  toggle: () => void;
  participant: ParticipantDetailType;
};

function AddInvoice({ isOpen, toggle, participant }: AddInvoiceProps) {
  const toast = useContext(ToastContext);
  const program = useContext(ProgramContext);
  const split = useContext(SplitIOContext);
  const [feature_remove_gross_amount, setFeatureRemoveGrossAmount] =
    useState(false);
  const [isBtnActive, setIsBtnActive] = useState(true);
  const [seasons, setSeasons] = useState<SnapSelectMenuOption[]>([]);
  const [selectedSeasonId, setSelectedSeasonId] = useState<string | undefined>(
    undefined
  );
  const [groups, setGroups] = useState<SnapSelectMenuOption[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(
    undefined
  );
  const [groupRosterId, setGroupRosterId] = useState("");
  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");
  const [date, setDate] = useState("");
  const [amountGuardianPays, setAmountGuardianPays] = useState("");
  const [amountGuardianPaysNew, setAmountGuardianPaysNew] = useState("");
  const [grossCollection, setGrossCollection] = useState("0.00");
  const [budgetItems, setBudgetItems] = useState<SnapSelectMenuOption[]>([]);
  const [selectedBudgetItemId, setSelectedBudgetItemId] = useState<
    string | undefined
  >(undefined);
  const [optOut, setOptOut] = useState(false);
  const [hasErrors, setHasErrors] = useState<parentAddInvoicesErrors>({
    budgetItemError: false,
    descriptionErorr: false,
    dateErorr: false,
    amountGuardianPaysError: false,
    minAmountError: false,
    seasonError: false,
    groupError: false,
    rosterError: false,
  });
  useEffect(() => {
    if (split) {
      setFeatureRemoveGrossAmount(
        split?.isTreatmentOn("spe-ffe-remove-gross-amount")
      );
    }
  }, [split]);

  const styleClass = "mt-5 lg:mt-0";

  const [addInvoice, { loading: loadingAddInvoice, data: addInvoiceData }] =
    useMutation(CREATE_INVOICE, {
      refetchQueries: ["GetParticipantsDetails"],
      fetchPolicy: "network-only",
    });

  const [getBudgets, { loading: loadingBudgets, data: budgetData }] =
    useSpendCategoriesAddInvoiceLazyQuery();

  useEffect(() => {
    if (
      !loadingAddInvoice &&
      addInvoiceData &&
      addInvoiceData.spendInvoiceCreate
    ) {
      toast?.setToast({
        message: "Invoice added successfully",
        type: "success",
      });
      setIsBtnActive(true);
      handleResetErrors();
      handleResetValues();
      toggle();
    }
    // eslint-disable-next-line
  }, [loadingAddInvoice, addInvoiceData]);

  useEffect(() => {
    let groupMenuOptions: SnapSelectMenuOption[] =
      participant?.Group.filter((option, index, arr) => {
        return (
          arr.findIndex((item) => item.groupId === option.groupId) === index
        );
      }).map((group) => {
        let option: SnapSelectMenuOption = {
          name: group.name,
          value: group.groupId,
          selected: false,
        };
        return option;
      }) ?? [];
    if (groupMenuOptions && groupMenuOptions.length === 1) {
      groupMenuOptions[0].selected = true;
      setSelectedGroupId(groupMenuOptions.at(0)?.value);
    }
    setGroups(groupMenuOptions);
  }, [participant]);

  useEffect(() => {
    let seasonMenuOptions: SnapSelectMenuOption[] =
      participant?.Group.filter(
        (group) => group.groupId === selectedGroupId
      ).map((group) => {
        let option: SnapSelectMenuOption = {
          name: group.season,
          value: group.seasonId,
          selected: false,
        };
        return option;
      }) ?? [];
    if (seasonMenuOptions && seasonMenuOptions.length === 1) {
      seasonMenuOptions[0].selected = true;
      setSelectedSeasonId(seasonMenuOptions.at(0)?.value);
    }
    setSeasons(seasonMenuOptions);
    // eslint-disable-next-line
  }, [selectedGroupId]);

  useEffect(() => {
    const foundGroupRoster = participant?.Group.find(
      (g) => g.groupId === selectedGroupId && g.seasonId === selectedSeasonId
    );
    if (foundGroupRoster) {
      setGroupRosterId(foundGroupRoster.groupRosterId);
    }
    if (foundGroupRoster?.sharedAccount) {
      let budgetMenuOptions: SnapSelectMenuOption[] =
        program
          ?.getBudgets()
          .filter((budget) => budget.category.type === "income")
          .map((budget) => {
            return {
              name: budget.description ?? "",
              value: budget.id ?? "",
              selected: false,
            };
          }) ?? [];
      if (budgetMenuOptions && budgetMenuOptions.length === 1) {
        budgetMenuOptions[0].selected = true;
        setSelectedBudgetItemId(budgetMenuOptions[0].value);
      }
      setBudgetItems(budgetMenuOptions);
    } else {
      getBudgets({
        variables: {
          groupId: selectedGroupId,
          seasonId: selectedSeasonId,
          filterBy: CategoryFilterEnum.Type,
          filterValue: "income",
        },
      });
    }

    // eslint-disable-next-line
  }, [selectedSeasonId, program]);

  useEffect(() => {
    if (!loadingBudgets && budgetData?.spendCategories) {
      let budgetMenuOptions: SnapSelectMenuOption[] = [];
      budgetMenuOptions =
        budgetData.spendCategories.categories?.flatMap((cat) => {
          return (
            cat?.budgets?.map((budget) => {
              return {
                name: budget?.description ?? "",
                value: budget?.id ?? "",
                selected: false,
              };
            }) ?? []
          );
        }) ?? [];

      if (budgetMenuOptions && budgetMenuOptions.length === 1) {
        budgetMenuOptions[0].selected = true;
        setSelectedBudgetItemId(budgetMenuOptions[0].value);
      }
      setBudgetItems(budgetMenuOptions);
    }
  }, [loadingBudgets, budgetData]);

  const handleAddInvoice = () => {
    let info = {
      rosterId: groupRosterId,
      groupId: selectedGroupId,
      season: selectedSeasonId,
      budgetItem: selectedBudgetItemId,
      description: description,
      date: date,
      amountGuardianPays: feature_remove_gross_amount
        ? amountGuardianPaysNew
        : amountGuardianPays,
      grossCollection: grossCollection,
      optOut: optOut,
      note: note,
    };
    const parsedAmount = ParseMoney(info.amountGuardianPays);
    if (info.rosterId === "" || !info.rosterId) {
      setHasErrors({ ...hasErrors, rosterError: true });
      hasErrors.groupError = true;
    }
    if (info.groupId === "" || !info.groupId) {
      setHasErrors({ ...hasErrors, groupError: true });
      hasErrors.groupError = true;
    }
    if (info.season === "" || !info.season) {
      setHasErrors({ ...hasErrors, seasonError: true });
      hasErrors.seasonError = true;
    }
    if (info.budgetItem === "" || !info.budgetItem) {
      setHasErrors({ ...hasErrors, budgetItemError: true });
      hasErrors.budgetItemError = true;
    }
    if (info.description === "") {
      setHasErrors({ ...hasErrors, descriptionErorr: true });
      hasErrors.descriptionErorr = true;
    }
    if (isNullOrEmpty(info.date)) {
      setHasErrors({ ...hasErrors, dateErorr: true });
      hasErrors.dateErorr = true;
    }
    if (info.amountGuardianPays === "" || info.amountGuardianPays === "0.00") {
      setHasErrors({ ...hasErrors, amountGuardianPaysError: true });
      hasErrors.amountGuardianPaysError = true;
    }

    if (parsedAmount <= 50) {
      setHasErrors({ ...hasErrors, minAmountError: true });
      hasErrors.minAmountError = true;
    }
    let {
      seasonError,
      budgetItemError,
      descriptionErorr,
      dateErorr,
      amountGuardianPaysError,
      minAmountError,
      rosterError,
    } = hasErrors;
    if (minAmountError) {
      toast?.setToast({
        message: "Invoice must be greater than $0.50",
        type: "danger",
      });
    } else if (
      seasonError ||
      budgetItemError ||
      descriptionErorr ||
      dateErorr ||
      amountGuardianPaysError
    ) {
      toast?.setToast({
        message: "Please fill in all the input fields",
        type: "danger",
      });
    } else if (rosterError) {
      toast?.setToast({
        message: "Group Roster id is missing",
        type: "danger",
      });
    } else {
      let input = {
        amount: parsedAmount,
        paid: false,
        dueDate: info.date,
        optedIn: info.optOut === false,
        balanceDue: calcTotalPlusFee(
          parsedAmount,
          program?.organization?.achPercent ?? 0,
          program?.organization?.achBaseFee ?? 0
        ),
        description: info.description,
        isOptional: info.optOut,
        budgetItemId: info.budgetItem,
        groupRosterId: info.rosterId,
        note: info.note,
      };
      setIsBtnActive(false);
      addInvoice({
        variables: {
          input,
        },
      });
    }
  };

  const handleResetErrors = () => {
    setHasErrors({
      seasonError: false,
      budgetItemError: false,
      descriptionErorr: false,
      dateErorr: false,
      amountGuardianPaysError: false,
      groupError: false,
      minAmountError: false,
      rosterError: false,
    });
  };

  const handleResetValues = () => {
    setDescription("");
    setNote("");
    setDate("");
    setAmountGuardianPays("");
    setAmountGuardianPaysNew("");
    setGrossCollection("0.00");
    setOptOut(false);
    setGroups(
      groups.length > 1
        ? groups.map((group) => {
            return { ...group, selected: false };
          })
        : groups
    );
    setSeasons(
      groups.length === 1 && seasons.length > 1
        ? seasons.map((season) => {
            return { ...season, selected: false };
          })
        : groups.length === 1 && seasons.length === 1
        ? seasons
        : []
    );
    setBudgetItems(
      seasons.length === 1 && budgetItems.length > 1
        ? budgetItems.map((item) => {
            return { ...item, selected: false };
          })
        : seasons.length === 1 && budgetItems.length === 1
        ? budgetItems
        : []
    );
  };

  return (
    <CustomModal
      customStyle="lg:mt-[30px]"
      isOpen={isOpen}
      toggle={() => {
        handleResetErrors();
        handleResetValues();
        if (toast?.isToastOpen) {
          toast.toggleToast();
        }
        setIsBtnActive(true);
        toggle();
      }}
      title={"Add Invoice"}
      btn1={{
        text: "Add Invoice",
        btnStyle: "primary",
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
        onClick: handleAddInvoice,
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          handleResetErrors();
          handleResetValues();
          if (toast?.isToastOpen) {
            toast.toggleToast();
          }
          setIsBtnActive(true);
          toggle();
        },
      }}
    >
      <div className="modal-card">
        <div className="flex">
          <p className="text-gray-500 text-sm self-center">Participant</p>
          <p className="ml-4">{participant?.participantName}</p>
        </div>
        {groups.length > 1 ? (
          <SnapSelectMenu
            id="snap-select-menu"
            placeholder="Select Group"
            label="Group"
            className="mt-4 lg:grid grid-cols-2 gap-4"
            selectMenuOptions={groups}
            onSnap-select-menu-updated={(e) => {
              const selectedOption = e.detail.find((o) => o.selected);
              setSelectedGroupId(selectedOption?.value);
              setGroups(e.detail);
              setHasErrors({ ...hasErrors, groupError: false });
              setBudgetItems([]);
            }}
            error={hasErrors.groupError}
          />
        ) : (
          <div className="flex mt-4">
            <p className="text-gray-500 text-sm self-center">Group</p>
            <p className="ml-4">{groups[0]?.name}</p>
          </div>
        )}
        <div className=" mt-5 lg:grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm">Season</p>
            <SnapSelectMenu
              id="snap-select-menu"
              placeholder={"Select Season"}
              modalTitle="Season"
              selectMenuOptions={seasons}
              onSnap-select-menu-updated={async (e) => {
                const selectedOption = e.detail.find((o) => o.selected);
                setSelectedSeasonId(selectedOption?.value);
                setSeasons(e.detail);
                setHasErrors({ ...hasErrors, seasonError: false });
              }}
              error={hasErrors.seasonError}
            />
          </div>
          <div className={styleClass}>
            <p className="text-sm">Income Budget Item</p>
            <SnapSelectMenu
              id="snap-select-menu"
              placeholder="Select Budget Item"
              modalTitle="Income Budget Item"
              selectMenuOptions={budgetItems}
              onSnap-select-menu-updated={(e) => {
                const selectedOption = e.detail.find((o) => o.selected);
                setSelectedBudgetItemId(selectedOption?.value);
                setBudgetItems(e.detail);
                setHasErrors({ ...hasErrors, budgetItemError: false });
              }}
              error={hasErrors.budgetItemError}
            />
          </div>
          <div className={styleClass}>
            <SnapInput
              _id="Description"
              label="Description"
              placeholder="e.g. Practice Fee, Uniform Fee, etc."
              _type="text"
              onSnap-input-change={(e) => {
                setDescription(e.detail.target.value);
                setHasErrors({ ...hasErrors, descriptionErorr: false });
              }}
              onBlur={(e) => {
                setDescription(e.target.value);
                setHasErrors({ ...hasErrors, descriptionErorr: false });
              }}
              error={hasErrors.descriptionErorr}
              errorIcon={hasErrors.descriptionErorr}
            />
          </div>
          <div className={styleClass}>
            <DatePicker
              label={"Date"}
              date={getDatePickerValue(date ?? "")}
              setDate={(e) => {
                const newDate = setDatePickerValue(e);
                setDate(newDate);
                setHasErrors({ ...hasErrors, dateErorr: false });
              }}
              hasError={hasErrors.dateErorr}
            />
          </div>
          <div className={styleClass}>
            {feature_remove_gross_amount ? (
              <InputMask
                amount={amountGuardianPaysNew}
                setAmount={setAmountGuardianPaysNew}
                hasError={hasErrors.amountGuardianPaysError}
                onChange={() => {
                  setHasErrors({
                    ...hasErrors,
                    amountGuardianPaysError: false,
                    minAmountError: false,
                  });
                }}
                label="Amount Guardian Pays"
                labelStyle="text-sm"
              />
            ) : (
              <InputMask
                amount={amountGuardianPays}
                setAmount={setAmountGuardianPays}
                hasError={hasErrors.amountGuardianPaysError}
                onChange={() => {
                  setHasErrors({
                    ...hasErrors,
                    amountGuardianPaysError: false,
                    minAmountError: false,
                  });
                }}
                label="Amount Guardian Pays"
                labelStyle="text-sm"
              />
            )}
          </div>
          {feature_remove_gross_amount ? null : (
            <div className={styleClass}>
              <div className="flex flex-col w-full">
                <p className={"text-sm"}>Gross Collection</p>
                <div className="flex">
                  <p className="flex items-center border-2 border-r-0 rounded-lg rounded-r-none px-2 text-sm text-gray-500 bg-gray-100">
                    $
                  </p>
                  <input
                    type="text"
                    className="border-2 h-10 rounded-l-none rounded-lg w-full px-4 bg-gray-100"
                    value={FormatMoney(
                      calcTotalPlusFee(
                        Number(amountGuardianPays) * 100,
                        program?.organization?.achPercent ?? 0,
                        program?.organization?.achBaseFee ?? 0
                      )
                    )}
                    disabled
                  />
                </div>
              </div>
              <p className="text-sm text-gray-500">
                Snap! Spend fees are {program?.organization?.achPercent}% +{" "}
                {FormatMoney(program?.organization?.achBaseFee ?? 0)} per
                payment.
              </p>
            </div>
          )}
        </div>
        <div className="mt-4">
          <SnapCheckboxButton
            className="mb-4 hidden lg:flex"
            label="Allow participant to opt out of this invoice."
            checked={optOut}
            onClick={() => setOptOut(!optOut)}
          />
          <SnapInput
            _id="note-input"
            label="Note"
            _type="text"
            textarea={true}
            cornerHint="Optional"
            onSnap-input-change={(e) => setNote(e.detail.target.value)}
            onBlur={(e) => setNote(e.target.value)}
          />
        </div>
      </div>
    </CustomModal>
  );
}

export default AddInvoice;
