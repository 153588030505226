import { useQuery } from "@apollo/client";
import DisplayContext from "context/display-context";
import ProgramContext from "context/program-context";
import UserContext from "context/user-context";
import { SpendBankAccount } from "graphql/generated";
import { GET_ORG_BANK_ACCOUNTS } from "graphql/queries/organization";
import useModal from "hooks/use-modal";
import useToast from "hooks/use-toast";
import { useContext, useEffect, useState } from "react";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import AccountOverViewCard from "shared-components/account-overview-card";
import BadgedTabs from "shared-components/badged-tabs";
import Transfer from "shared-components/banking/transfer";
import Divider from "shared-components/divider";
import FloatingActionBtn from "shared-components/floating-action-btn";
import ToastMessage from "shared-components/toast-message";
import { SnapActionSheet, SnapButton, SnapLabeledToggle } from "suit";
import { bankActions } from "types/club-banking";
import { SpendPermissions } from "types/roles-permissions";
import { tabs } from "./metadata/tabs";
import { transactionMenuItems } from "./metadata/transaction-menu-items";
import Transactions from "./transactions";
import LegacyTransactions from "shared-components/banking/legacy-transactions";
import SendMoney from "shared-components/banking/send-money";
import MigrationAlert from "shared-components/banking/migration-alert";

function ProgramBanking() {
  const display = useContext(DisplayContext);
  const program = useContext(ProgramContext);
  const sessionUser = useContext(UserContext);

  const { isOpen: isTransferOpen, toggle: transferToggle } = useModal();
  const { isOpen: isSendMoneyOpen, toggle: sendMoneyToggle } = useModal();

  const { isToastOpen, ...toast } = useToast();

  const [legacyTransactionsOpen, setLegacyTransactionsOpen] = useState(false);
  const [hasLinkedAccount, setHasLinkedAccount] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isBankOptionsOpen, setIsBankOptionsOpen] = useState(false);
  const [linkedAccount, setLinkedAccount] = useState<
    SpendBankAccount | undefined
  >(undefined);
  const [accountStatus, setAccountStatus] = useState("");
  const canUpdateProgramBanking =
    sessionUser?.checkSpendPermission(SpendPermissions.programBankUpdate) ??
    false;
  const canUpdateBudgetItem =
    sessionUser?.checkSpendPermission(SpendPermissions.programBudgetUpdate) ??
    false;
  const guardedTransactionMenuItems = canUpdateBudgetItem
    ? transactionMenuItems
    : [];

  const { loading: loadingAccount, data: accountData } = useQuery(
    GET_ORG_BANK_ACCOUNTS,
    {
      fetchPolicy: "network-only",
    }
  );
  const [isExternalTransferEnabled, setisExternalTransferEnabled] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    if (accountData && accountData.spendOrganizationBankAccounts) {
      setLinkedAccount(
        accountData.spendOrganizationBankAccounts.externalAccounts.at(0)
      );
      setAccountStatus(accountData.spendOrganizationBankAccounts.status);
      setHasLinkedAccount(
        accountData.spendOrganizationBankAccounts.externalAccounts.length
      );
    }
    setisExternalTransferEnabled(program?.getExternalTransferOutEnabled());
  }, [
    loadingAccount,
    accountData,
    program?.organization?.hasLinkedAccount,
    program,
  ]);

  let bankingActions: bankActions[] = [
    {
      icon: "cash-solid",
      label: "Deposit",
      onClick: () => {
        display?.setSelectedUnitAccount(
          program?.getProgramAccount()?.id ?? "",
          program?.getProgramAccount()?.name ?? "",
          program?.getProgramAccount()?.id ?? "",
          program?.organization?.nickname ||
            program?.organization?.legalName ||
            ""
        );
      },
      className: "mt-2 lg:hidden",
    },
    {
      icon: "switch-horizontal-solid",
      label: "Transfer",
      onClick: transferToggle,
      className: "mt-4",
    },
    {
      icon: "cash-solid",
      label: "Send Money",
      onClick: sendMoneyToggle,
      className: "mt-4",
    },
  ];

  const [plaidConfig, setPlaidConfig] = useState<PlaidLinkOptions>({
    onSuccess: (public_token, metadata) => {
      console.log("HIT SUCCESS");
    },
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: "",
  });
  const { open, ready } = usePlaidLink(plaidConfig);
  const openPlaid = () => {
    if (ready) {
      open();
    }
  };

  return (
    <div className={`wrapper`}>
      {isToastOpen && (
        <ToastMessage
          title={toast.title}
          message={toast.message}
          isToastOpen={isToastOpen}
          toggleToast={toast.toggleToast}
          type={toast.type}
          className="lg:top-6"
        />
      )}
      <MigrationAlert />
      <AccountOverViewCard
        bankingActions={bankingActions}
        hasLinkedAccount={hasLinkedAccount}
        openPlaid={openPlaid}
        setPlaidConfig={setPlaidConfig}
        toast={toast}
        accountType="program"
        linkedAccount={linkedAccount}
        canUpdateBanking={canUpdateProgramBanking}
        canAddAccount={canUpdateProgramBanking}
        accountStatus={accountStatus}
        isExternalTransferEnabled={isExternalTransferEnabled}
      />
      <div className="card">
        <div>
          <BadgedTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
        <Transactions
          isFilter={true}
          menuItems={guardedTransactionMenuItems}
          canUpdateBudgetItem={canUpdateBudgetItem}
        />

        {program?.organization?.legacyAccountId != null && (
          <div>
            <div>
              <SnapLabeledToggle
                checked={legacyTransactionsOpen}
                label="Legacy Transactions"
                onClick={() =>
                  setLegacyTransactionsOpen(!legacyTransactionsOpen)
                }
              />
            </div>
            {legacyTransactionsOpen && (
              <LegacyTransactions
                legacyAccountId={program.organization.legacyAccountId}
                menuItems={guardedTransactionMenuItems}
                canUpdateBudgetItem={canUpdateBudgetItem}
              />
            )}
          </div>
        )}
      </div>
      {canUpdateProgramBanking && (
        <FloatingActionBtn
          onClick={() => setIsBankOptionsOpen(true)}
          icon={"dots-horizontal-solid"}
        />
      )}
      {isBankOptionsOpen && (
        <SnapActionSheet
          header="Banking Actions"
          onClick={() => setIsBankOptionsOpen(false)}
        >
          <Divider isVisibleOnMobile className="mt-0" />
          {bankingActions.map((actionItem, idx) => {
            return (
              <SnapButton
                key={idx}
                fullWidth
                icon={actionItem.icon}
                onClick={actionItem.onClick}
                className={`${actionItem.className} mx-6`}
                variant="secondary"
                size="md"
              >
                {actionItem.label}
              </SnapButton>
            );
          })}
        </SnapActionSheet>
      )}
      <Transfer
        type={"program"}
        isTransferOpen={isTransferOpen}
        transferToggle={transferToggle}
        toast={toast}
        hasLinkedAccount={hasLinkedAccount}
        openPlaid={openPlaid}
        unitAmount={program?.getProgramAccount()?.available}
        linkedAccount={linkedAccount}
      />
      <SendMoney
        isSendMoneyOpen={isSendMoneyOpen}
        sendMoneyToggle={sendMoneyToggle}
        type={"Program"}
      />
    </div>
  );
}

export default ProgramBanking;
