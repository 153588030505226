import { useLazyQuery, useQuery } from "@apollo/client";
import DisplayContext from "context/display-context";
import {
  Maybe,
  SpendBankTransaction,
  SpendGuardianActiveGroup,
  SpendGuardianComingSoonInvoice,
  SpendGuardianInvoice,
  SpendInvoice,
  SpendSeason,
  SpendTransactionsFilteredForParentDashQuery,
  SpendTransactionsFilteredForParentDashQueryVariables,
} from "graphql/generated";
import { GET_SPEND_BUDGET_SUMMARY } from "graphql/queries/budgets";
import { GET_GUARDIAN_TRANSACTIONS } from "graphql/queries/transactions";
import { GET_GUARDIAN_HIGHLIGHT_2 } from "graphql/queries/user";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import {
  BudgetSummaryConvertDataToUiModels,
  getHexColor,
} from "helpers/summary-related";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ColorBar from "shared-components/color-bar";
import DataCard from "shared-components/data-card";
import Divider from "shared-components/divider";
import FloatingActionBtn from "shared-components/floating-action-btn";
import { HorizontalValueStyle } from "shared-components/horizontal-label-value";
import CustomModal from "shared-components/modal";
import { ChangePaymentModal } from "shared-components/modal/change-payment/change-payment-modal";
import { MakePaymentModal } from "shared-components/modal/make-payment/make-payment-modal";
import {
  PaymentConfirmationModal,
  StripeResponse,
} from "shared-components/modal/payment-confirmation-modal";
import TransactionDetails from "shared-components/modal/transaction-details.tsx";
import NumberCard from "shared-components/number-card";
import {
  SnapActionSheet,
  SnapAlert,
  SnapAlertAction,
  SnapAlertDescription,
  SnapAlertTitle,
  SnapButton,
  SnapIcon,
  SnapLink,
} from "suit";
import {
  BudgetSummaryRecord,
  BudgetTotalValue,
  UnreconciledRecord,
} from "types/budget-summary";
import { LabelValueObject } from "types/label-value-object";
import InvoicesComingDue from "./InvoicesComingDue";
import ParentLeaveTeamModal from "./ParentLeaveTeamModal";
import ViewUnauthorizedInvoicesModal from "./ViewUnauthorizedInvoicesModal";
import ToastContext from "context/toast-context";
import { getBadgeColor } from "helpers/status-color";
import { getTransactionTitle } from "../../../helpers/transaction";
import { LATEST_TRANSACTION_NUMBER } from "../../../constants";
import { useContextStrict } from "../../../helpers/context-strict";
import ProgramContext from "context/program-context";
import GroupContext from "context/group-context";
import UserContext from "context/user-context";
import { doubleDigit } from "helpers/format-phone-number";
import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { SelectedSpendGuardianComingSoonInvoice } from "types/invoice";
import { INVOICE_AUTOPAY } from "graphql/queries/invoices";
import { groupBy } from "helpers/list";
import { SpinnerContainer } from "shared-components/spinner";
import AuthorizeAutoPay from "shared-components/modal/authorize-autopay";

export type StopPaymentFormType = {
  invoice?: SpendInvoice | SpendGuardianInvoice;
  message?: string;
  groupName?: string;
  seasonName?: string;
};

export type activeGroup = {
  groupId: string;
  groupName: string;
  playerName: string;
  season: Maybe<SpendSeason> | undefined;
};

function ParentDashboard() {
  const ActiveProgram = useContext(ProgramContext);
  const ActiveGroup = useContext(GroupContext);
  const ActiveUser = useContext(UserContext);
  const Display = useContext(DisplayContext);
  const Toast = useContextStrict(ToastContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [transactionsSliceBy, setTransactionsSliceBy] = useState(
    LATEST_TRANSACTION_NUMBER
  );
  const {
    data: guardianHighlightNewData,
    loading: loadingGuardianHighlightNew,
  } = useQuery(GET_GUARDIAN_HIGHLIGHT_2);
  const [getBudgetData, { data: budgetData, loading: loadingBudget }] =
    useLazyQuery(GET_SPEND_BUDGET_SUMMARY);
  const { data: guardianTransactionData, loading: loadingGuardianTransaction } =
    useQuery<
      SpendTransactionsFilteredForParentDashQuery,
      SpendTransactionsFilteredForParentDashQueryVariables
    >(GET_GUARDIAN_TRANSACTIONS);
  const { isOpen, toggle } = useModal();

  const { isOpen: changePaymentOpen, toggle: changePaymentToggle } = useModal();
  const { isOpen: authorizeAutoPayOpen, toggle: authorizeAutoPayToggle } =
    useModal();
  const {
    isOpen: isTransactionDetailOpen,
    toggle: transactionDetailToggle,
    tabSelectedValue,
    setSelectedTab,
  } = useModal();
  const [selectedTran, setSelectedTran] = useState<
    SpendBankTransaction | undefined
  >(undefined);
  const [selectedModal, setSelectedModal] = useState(0);
  const [isUserArchived, setIsUserArchived] = useState(false);
  const [groups, setGroups] = useState<
    SpendGuardianActiveGroup[] | undefined
  >();
  const [groupSliceBy, setGroupSliceBy] = useState(5);
  const [invoices, setInvoices] = useState<SpendGuardianComingSoonInvoice[]>(
    []
  );
  const [recentTransactions, setRecentTransactions] = useState<
    SpendBankTransaction[]
  >([]);

  const [fabOpen, setFabOpen] = useState(false);
  const [stripeResponse, setStripeState] = useState<StripeResponse>({
    redirect_status: "spend_init",
  });

  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  useState(false);
  const [isUnauthorizedInvoicesOpen, setUnauthorizedInvoicesOpen] =
    useState(false);
  const [teamData, setTeamData] = useState<activeGroup>();
  const [showUnauthorizedInvoicesWarning, setShowUnauthorizedInvoicesWarning] =
    useState<Boolean>();
  const [currentUnauthorizedInvoice, setCurrentUnauthorizedInvoice] = useState<
    SelectedSpendGuardianComingSoonInvoice[]
  >([]);
  const [resetUnauthorizedInvoice, setResetUnauthorizedInvoice] = useState<
    SelectedSpendGuardianComingSoonInvoice[]
  >([]);
  const [unreconciledData, setUnreconciledData] = useState<UnreconciledRecord>({
    credit: { count: 0, total: 0 },
    debit: { count: 0, total: 0 },
  });
  const [incomeBudgetData, setIncomeBudgetData] = useState<
    BudgetSummaryRecord[] | []
  >([]);
  const [expenseBudgetData, setExpenseBudgetData] = useState<
    BudgetSummaryRecord[] | []
  >([]);
  const [incomeBudgetTotal, setIncomeBudgetTotal] = useState<BudgetTotalValue>({
    budgetTotal: 0,
    reconciledTotal: 0,
    percent: 0,
  });
  const [expenseBudgetTotal, setExpenseBudgetTotal] =
    useState<BudgetTotalValue>({
      budgetTotal: 0,
      reconciledTotal: 0,
      percent: 0,
    });
  const [selectedItem, setSelectedItem] = useState<activeGroup>({
    groupId: "",
    groupName: "",
    playerName: "",
    season: undefined,
  });
  const [isGroupRosterAutoPay, setGroupRosterAutoPay] = useState<
    | {
        id: string;
        isAuto: boolean;
        name: string;
        invoices: SpendInvoice[];
      }[]
    | undefined
  >([]);
  const { data: invoiceAutopay, loading: loadingInvoiceAutoPay } =
    useQuery(INVOICE_AUTOPAY);

  useEffect(() => {
    if (invoiceAutopay && !loadingInvoiceAutoPay) {
      const groupedAutoPay = groupBy<SpendInvoice, string>(
        invoiceAutopay.spendPaginatedInvoices.invoices,
        (i) => i.groupRosterId!
      );
      const isGroupRosterAutoPay = groupedAutoPay.keys
        .map((key) => {
          const firstInvoice = groupedAutoPay.result[key].at(0);
          return {
            id: key,
            name: firstInvoice?.groupRoster?.roster?.name || "",
            isAuto: groupedAutoPay.result[key].some((v) => {
              return (
                v.isAutoPayAuthorized === true ||
                v.groupRoster?.settings?.isAutoPayAuthorized === true
              );
            }),
            invoices: groupedAutoPay.result[key].filter(
              (invx) =>
                !invx.paid &&
                invx.balanceDue !== 0 &&
                invx.authorizedAt === null &&
                (invx.optedIn || (!invx.optedIn && invx.optedOutAt == null))
            ),
            isActive: firstInvoice?.groupRoster?.joinedAt != null,
          };
        })
        .filter((gr) => gr.invoices.length > 0 && gr.isActive);
      setGroupRosterAutoPay(isGroupRosterAutoPay);
    }
  }, [invoiceAutopay, loadingInvoiceAutoPay]);
  useEffect(() => {
    if (ActiveGroup?.groups?.length) {
      ActiveGroup?.setAndStoreActiveGroup(ActiveGroup.groups.at(0));
    }
  }, [ActiveProgram, ActiveGroup]);

  useEffect(() => {
    if (ActiveUser?._session?.role?.isArchived) {
      setIsUserArchived(true);
    }
  }, [ActiveUser]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("redirect_from")) {
      const redirect_from = queryParams.get("redirect_from");
      switch (redirect_from) {
        case "stripe":
          const data = atob(queryParams.get("data")!);
          const dataJson = JSON.parse(data);
          setStripeState({
            redirect_from: redirect_from,
            payment_intent: queryParams.get("payment_intent")!,
            payment_intent_client_secret: queryParams.get(
              "payment_intent_client_secret"
            )!,
            external_id: queryParams.get("external_id") ?? "",
            redirect_status: queryParams.get("redirect_status")!,
            data: dataJson.invoiceIds,
          });
          break;
        default:
          navigate(location.pathname);
      }
    }
  }, [location, navigate]);

  useEffect(() => {
    if (budgetData && budgetData?.spendBudgetsSummary) {
      BudgetSummaryConvertDataToUiModels(
        budgetData?.spendBudgetsSummary,
        setIncomeBudgetData,
        setExpenseBudgetData,
        setIncomeBudgetTotal,
        setExpenseBudgetTotal,
        setUnreconciledData
      );
      if (!isOpen) {
        toggle();
      }
    }
    // eslint-disable-next-line
  }, [budgetData, loadingBudget]);

  useEffect(() => {
    if (
      !loadingGuardianHighlightNew &&
      guardianHighlightNewData?.spendGuardianHighlight != null
    ) {
      const activeGroups =
        guardianHighlightNewData.spendGuardianHighlight.activeGroups.filter(
          (group: SpendGuardianActiveGroup) => !group.group?.isArchived
        );
      setGroups(activeGroups);
      const comingSoonInvoices =
        guardianHighlightNewData.spendGuardianHighlight.comingSoonInvoices;
      setInvoices(comingSoonInvoices);
      const unauthorizedInvoices =
        guardianHighlightNewData?.spendGuardianHighlight?.unauthorizedInvoices?.map(
          (invoice: SelectedSpendGuardianComingSoonInvoice) => {
            return {
              ...invoice,
              selected: false,
            };
          }
        ) ?? [];
      setCurrentUnauthorizedInvoice(unauthorizedInvoices);
      setResetUnauthorizedInvoice(unauthorizedInvoices);
      setShowUnauthorizedInvoicesWarning(unauthorizedInvoices.length > 0);
    }
  }, [guardianHighlightNewData, loadingGuardianHighlightNew]);

  useEffect(() => {
    if (!loadingGuardianTransaction && guardianTransactionData) {
      setRecentTransactions(
        guardianTransactionData.spendTransactionsFiltered
          ?.transactions as SpendBankTransaction[]
      );
    }
  }, [guardianTransactionData, loadingGuardianTransaction]);

  const prepActiveTeamsLD = (d: SpendGuardianActiveGroup) => {
    const labelValues: LabelValueObject[] = [];
    d.group?.seasons?.forEach((season) => {
      labelValues.push({ key: "Season", value: season?.name ?? "" });
      labelValues.push({
        key: "Participant(s)",
        value:
          (season?.roster &&
            season?.roster
              .map((roster, idx) =>
                idx !== season.roster?.length! - 1
                  ? `${roster?.name}, `
                  : roster?.name
              )
              .join(" ")) ??
          "",
      });
    });
    return labelValues;
  };

  const prepTransactionsLD = (d: SpendBankTransaction) => {
    const leftLabels: LabelValueObject[] = [];
    let group = d.groupRoster?.group?.name ?? d.external?.groupName;
    let participant = d.groupRoster?.roster?.name ?? d.external?.playerName;
    let paymentMethod = "-";
    let status = "unavailable";
    const transactionStatus = d.status?.toLowerCase();
    if (d.transactionStatus === "Returned") {
      status = "returned";
    } else if (d.type?.toLowerCase() === "refund") {
      status = "refund";
    } else if (transactionStatus === "settled") {
      status = "settled";
    } else {
      status = transactionStatus ?? "unavailable";
    }

    if (d.metadata) {
      paymentMethod = d.metadata.processor === "stripe" ? "Card" : "ACH";
    }

    if (d.transactionType === "credit_memo") {
      group = d.creditMemo?.groupName ?? "";
      participant = d.creditMemo?.invoice?.participantName;
    }

    leftLabels.push({
      key: "Group",
      value: group ?? "",
    });
    leftLabels.push({
      key: "Participant",
      value: participant ?? "",
    }); // dont have
    if (d.type !== "credit_memo") {
      leftLabels.push({
        key: "Payment Method",
        value: paymentMethod,
      });
      leftLabels.push({
        key: "Status",
        value: status,
        valueStyle: HorizontalValueStyle.Badge,
        valueColor: getBadgeColor(d.status ?? "Unavailable"),
      });
    }
    return leftLabels;
  };

  const prepTransactionsRD = (d: SpendBankTransaction) => {
    const rightLabels: LabelValueObject[] = [];
    rightLabels.push({
      key: "Date",
      value: FormatDate(d.effective ?? "", DateFormatSupported.Numbers),
    });
    const amountClassName =
      d.transactionStatus === "Returned"
        ? "text-gray-500 font-semibold italic"
        : undefined;
    rightLabels.push({
      key: "Amount",
      value: FormatMoney(Number(d.amount)),
      className: amountClassName,
    });
    return rightLabels;
  };

  const handleGetBudgetData = (groupId: string, seasonId: string) => {
    getBudgetData({
      variables: { groupId: groupId, seasonId: seasonId },
      fetchPolicy: "network-only",
    });
  };

  return (
    <div className="card">
      {isGroupRosterAutoPay?.map((auth) => {
        return (
          <div key={auth.id}>
            {showUnauthorizedInvoicesWarning && (
              <SnapAlert type="warning" className="mb-3">
                <SnapAlertTitle>
                  Participant Unauthorized Invoices
                </SnapAlertTitle>
                <SnapAlertDescription>
                  You have invoices for {auth.name} awaiting approval.
                </SnapAlertDescription>
                <SnapAlertAction slot="start">
                  <SnapLink
                    onClick={() => {
                      if (!!auth.isAuto) {
                        setCurrentUnauthorizedInvoice(
                          resetUnauthorizedInvoice.filter(
                            (cui) => cui.groupRosterId === auth.id
                          )
                        );
                        setUnauthorizedInvoicesOpen(true);
                      } else {
                        setFabOpen(false);
                        Display?.setMakePaymentDataModalOpen({
                          isOpen: true,
                          groupRosterId: auth.id,
                        });
                      }
                    }}
                  >
                    Click here to review and approve
                  </SnapLink>
                </SnapAlertAction>
              </SnapAlert>
            )}
          </div>
        );
      })}
      {showUnauthorizedInvoicesWarning && (
        <ViewUnauthorizedInvoicesModal
          data={currentUnauthorizedInvoice}
          isOpen={isUnauthorizedInvoicesOpen}
          toggle={() => setUnauthorizedInvoicesOpen(false)}
          successToastToggle={() => {
            Toast?.setToastProps({
              message: "Participant invoices have been authorized",
              type: "success",
            });
            Toast?.toggleToast();
          }}
          setCurrentUnauthorizedInvoice={setCurrentUnauthorizedInvoice}
        />
      )}

      {/* Small Card */}
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <NumberCard
          label="Active Groups"
          value={groups ? doubleDigit(groups.length) : "00"}
        />
        <NumberCard
          label="Upcoming Invoices"
          value={invoices ? doubleDigit(invoices.length) : "00"}
          middle
        />
        <NumberCard
          label="Recent Transactions"
          value={doubleDigit(recentTransactions.length)}
        />
      </div>

      {/* TEAMS */}
      {isUserArchived && groups && groups.length === 0 ? (
        <>
          <div className=" my-4 lg:mb-3">
            <div className="text-gray-800 text-lg font-semibold">
              Active Groups
            </div>
            <p>You are currently not in a group</p>
          </div>
          <div className="relative">
            <InvoicesComingDue invoices={invoices} />
            <SpinnerContainer loading={loadingGuardianHighlightNew} />
          </div>
        </>
      ) : (
        <>
          <div className="mt-6 relative min-h-[100px]">
            <div className="flex justify-between mb-4 lg:mb-3">
              <div className="text-gray-800 text-lg font-semibold">
                Active Groups
              </div>

              {groups && groups.length > 5 && (
                <p
                  className="font-bold text-blue-600 cursor-pointer"
                  onClick={() => {
                    if (groupSliceBy === 5) {
                      setGroupSliceBy(groups.length);
                    } else {
                      setGroupSliceBy(5);
                    }
                  }}
                >
                  See {groupSliceBy === 5 ? "All" : "Less"}
                </p>
              )}
            </div>

            {groups &&
              groups
                .slice(0, groupSliceBy)
                .map((dat: SpendGuardianActiveGroup, idx: number) => {
                  let options: DropdownMenuItem[] = [
                    {
                      name: "Leave Group",
                      text: "Leave Group",
                      value: "1",
                    },
                  ];
                  if (dat.group?.latestSeason?.isBudgetShared) {
                    options.unshift({
                      name: "View Group Budget",
                      text: "View Group Budget",
                      value: "2",
                    });
                  }
                  return (
                    <DataCard
                      className="mb-2"
                      key={`Active-${idx}`}
                      title={dat.group?.name ?? ""}
                      titleAction={
                        dat.group?.latestSeason?.isBudgetShared
                          ? () => {
                              handleGetBudgetData(
                                dat.group?.id!,
                                dat.group?.latestSeason?.id!
                              );
                              setSelectedItem({
                                groupId: dat.group?.id ?? "",
                                groupName: dat.group?.name ?? "",
                                playerName: "",
                                season: dat.group?.latestSeason,
                              });
                            }
                          : undefined
                      }
                      kvLeft={prepActiveTeamsLD(dat)}
                      kvRight={[]}
                      action={1} // 1 is menu and only needs menuClickListener
                      menuItems={options}
                      menuClickListener={(e) => {
                        if (e === "1") {
                          setTeamData({
                            groupId: dat.group?.id ?? "",
                            groupName: dat.group?.name ?? "",
                            playerName: "",
                            season: dat.group?.latestSeason,
                          });
                          setIsLeaveModalOpen(true);
                        } else {
                          handleGetBudgetData(
                            dat.group?.id!,
                            dat.group?.latestSeason?.id!
                          );
                          setSelectedItem({
                            groupId: dat.group?.id ?? "",
                            groupName: dat.group?.name ?? "",
                            playerName: "",
                            season: dat.group?.latestSeason,
                          });
                        }
                      }}
                      titleHasBorder={false}
                    />
                  );
                })}
            {isLeaveModalOpen && (
              <ParentLeaveTeamModal
                teamData={teamData}
                isOpen={isLeaveModalOpen}
                toggle={() => setIsLeaveModalOpen(false)}
              />
            )}
            <SpinnerContainer loading={loadingGuardianHighlightNew} />
          </div>
          {/* INVOICES */}
          <div className="relative">
            <InvoicesComingDue invoices={invoices} />
            <SpinnerContainer loading={loadingGuardianHighlightNew} />
          </div>
        </>
      )}

      {/* RECENT TRANSACTIONS */}
      <div className="mt-6">
        <div className="flex justify-between mb-4 lg:mb-3">
          <div className="text-gray-800 text-lg font-semibold">
            Recent Transactions
          </div>
          {recentTransactions.length > 5 && (
            <p
              className="font-bold text-blue-600 cursor-pointer"
              onClick={() => {
                if (transactionsSliceBy === 5) {
                  setTransactionsSliceBy(recentTransactions.length);
                } else {
                  setTransactionsSliceBy(5);
                }
              }}
            >
              See {transactionsSliceBy === 5 ? "All" : "Less"}
            </p>
          )}
        </div>
        <div className="relative">
          {recentTransactions &&
            recentTransactions
              .slice(0, transactionsSliceBy)
              .map((dat: SpendBankTransaction, idx: number) => {
                return (
                  <DataCard
                    className="mb-2"
                    key={`transactions-${dat.id || idx}`}
                    title={getTransactionTitle(dat)}
                    // subTitle={dat.v3}
                    kvLeft={prepTransactionsLD(dat)}
                    kvRight={prepTransactionsRD(dat)}
                    titleAction={() => {
                      setSelectedTran(dat);
                      transactionDetailToggle();
                    }}
                    titleHasBorder={false}
                  />
                );
              })}
          <SpinnerContainer loading={loadingGuardianTransaction} />
        </div>
      </div>

      <FloatingActionBtn
        onClick={() => setFabOpen(true)}
        icon={"dots-horizontal-solid"}
      />
      {fabOpen && (
        <SnapActionSheet onClick={() => setFabOpen(false)}>
          <Divider isVisibleOnMobile className="mb-4" />
          <div className="mx-4">
            <SnapButton
              variant="primary"
              size="md"
              onClick={() => {
                setFabOpen(false);
                authorizeAutoPayToggle();
              }}
              fullWidth
              className="mb-4"
            >
              Authorize AutoPay
            </SnapButton>
            <SnapButton
              variant="primary"
              size="md"
              onClick={() => {
                setFabOpen(false);
                changePaymentToggle();
              }}
              fullWidth
              className="mb-4"
            >
              Change Payment Method
            </SnapButton>
            <SnapButton
              variant="primary"
              size="md"
              onClick={() => {
                setFabOpen(false);
                Display?.setMakePaymentDataModalOpen({ isOpen: true });
              }}
              fullWidth
              className="mb-4"
            >
              Make Payment
            </SnapButton>
            {stripeResponse.redirect_from != null &&
              ["spend_init", "success"].includes(
                stripeResponse.redirect_status
              ) && <PaymentConfirmationModal stripeResponse={stripeResponse} />}
          </div>
        </SnapActionSheet>
      )}
      {authorizeAutoPayOpen && (
        <AuthorizeAutoPay
          isOpen={authorizeAutoPayOpen}
          toggle={authorizeAutoPayToggle}
        />
      )}

      <MakePaymentModal
        setFabOpen={setFabOpen}
        makePaymentModalOpen={Display?.makePaymentModalOpen}
        setMakePaymentModalOpen={Display?.setMakePaymentModalOpen}
        makePaymentDataModalOpen={Display?.makePaymentDataModalOpen}
        setMakePaymentDataModalOpen={Display?.setMakePaymentDataModalOpen}
      />

      {isTransactionDetailOpen && selectedTran && (
        <TransactionDetails
          isOpen={isTransactionDetailOpen}
          toggle={transactionDetailToggle}
          tabSelectedValue={tabSelectedValue}
          selectedTran={selectedTran}
          setSelectedModal={setSelectedModal}
          setSelectedTab={setSelectedTab}
          selectedModal={selectedModal}
          canEditNotesAndAttachments={true}
          isParentView={true}
        />
      )}

      {changePaymentOpen && (
        <ChangePaymentModal
          isOpen={changePaymentOpen}
          toggle={changePaymentToggle}
        />
      )}

      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        title={selectedItem.groupName}
        customStyle="lg:w-[974px]"
        header={`${FormatDate(
          new Date(selectedItem.season?.startDateAt ?? ""),
          DateFormatSupported.Words
        )} - ${FormatDate(
          new Date(selectedItem.season?.endDateAt ?? ""),
          DateFormatSupported.Words
        )}`}
      >
        {/* Modal Details Card */}
        <div className="modal-card overflow-scroll lg:h-[500px]">
          <h1 className="lg:text-lg font-medium lg:font-semibold text-gray-600 lg:tetx-gray-800">
            Income Summary
          </h1>
          {incomeBudgetData &&
            incomeBudgetData.map((iBudgetDat) => {
              let percent =
                (iBudgetDat.budgetReconciled / iBudgetDat.budgetAmount) * 100;
              let color = getHexColor(
                iBudgetDat.budgetAmount,
                iBudgetDat.budgetReconciled,
                false,
                false
              );
              return (
                <div key={iBudgetDat.name}>
                  {Display?.isDesktop ? (
                    <BudgetSummaryRowLG
                      budgetData={iBudgetDat}
                      percent={percent}
                      color={color}
                    />
                  ) : (
                    <BudgetSummaryRowSM
                      budgetData={iBudgetDat}
                      percent={percent}
                      color={color}
                    />
                  )}
                </div>
              );
            })}
          <div className="border px-4 py-2 rounded-lg mt-4 lg:flex bg-gray-100 border-gray-200 hidden">
            <p className="font-semibold flex items-center mr-auto">
              Income Total
            </p>
            <div className="border-r pr-3">
              <p className="text-xs font-bold text-gray-500">Budgeted</p>
              <p>{FormatMoney(incomeBudgetTotal.budgetTotal)}</p>
            </div>
            <div className="border-r  pl-3 pr-3">
              <p className="text-xs font-bold text-gray-500">Reconciled</p>
              <p>{FormatMoney(incomeBudgetTotal.reconciledTotal)}</p>
            </div>
            <div className="w-72 mr-9 ml-3 flex items-center pr-3">
              <ColorBar
                percent={incomeBudgetTotal.percent}
                colorHex={getHexColor(
                  incomeBudgetTotal.budgetTotal,
                  incomeBudgetTotal.reconciledTotal,
                  false,
                  false
                )}
              />
            </div>
          </div>
          <div className="border rounded-lg p-4 mt-4 bg-gray-100 border-gray-200 lg:hidden">
            <p className="font-semibold mb-1">Income Total</p>
            <ColorBar
              percent={incomeBudgetTotal.percent}
              colorHex={getHexColor(
                incomeBudgetTotal.budgetTotal,
                incomeBudgetTotal.reconciledTotal,
                false,
                false
              )}
            />
            <Divider isVisibleOnMobile />
            <div className="mt-4 flex justify-between">
              <p className="text-xs font-bold text-gray-500">Budget</p>
              <p>{FormatMoney(incomeBudgetTotal.budgetTotal)}</p>
            </div>
            <div className="mt-4 flex justify-between">
              <p className="text-xs font-bold text-gray-500">Reconciled</p>
              <p>{FormatMoney(incomeBudgetTotal.reconciledTotal)}</p>
            </div>
          </div>
          <Divider isVisibleOnMobile />
          <h1 className="lg:text-lg font-medium lg:font-semibold text-gray-600 lg:tetx-gray-800 mt-4">
            Expense Summary
          </h1>
          {expenseBudgetData &&
            expenseBudgetData.map((eBudgetDat) => {
              let percent =
                (eBudgetDat.budgetReconciled / eBudgetDat.budgetAmount) * 100;
              let color = getHexColor(
                eBudgetDat.budgetAmount,
                eBudgetDat.budgetReconciled,
                false,
                false
              );
              return (
                <div key={eBudgetDat.name}>
                  {Display?.isDesktop ? (
                    <BudgetSummaryRowLG
                      budgetData={eBudgetDat}
                      percent={percent}
                      color={color}
                    />
                  ) : (
                    <BudgetSummaryRowSM
                      budgetData={eBudgetDat}
                      percent={percent}
                      color={color}
                    />
                  )}
                </div>
              );
            })}
          <div className="border px-4 py-2 rounded-lg mt-4 lg:flex bg-gray-100 border-gray-200 hidden">
            <p className="font-semibold flex items-center mr-auto">
              Expense Total
            </p>
            <div className="border-r pr-3">
              <p className="text-xs font-bold text-gray-500">Budgeted</p>
              <p>{FormatMoney(expenseBudgetTotal.budgetTotal)}</p>
            </div>
            <div className="border-r  pl-3 pr-3">
              <p className="text-xs font-bold text-gray-500">Reconciled</p>
              <p>{FormatMoney(expenseBudgetTotal.reconciledTotal)}</p>
            </div>
            <div className="w-72 mr-9 ml-3 flex items-center pr-3">
              <ColorBar
                percent={expenseBudgetTotal.percent}
                colorHex={getHexColor(
                  expenseBudgetTotal.budgetTotal,
                  expenseBudgetTotal.reconciledTotal,
                  false,
                  false
                )}
              />
            </div>
          </div>
          <div className="border rounded-lg p-4 mt-4 bg-gray-100 border-gray-200 lg:hidden">
            <p className="font-semibold mb-1">Expense Total</p>
            <ColorBar
              percent={expenseBudgetTotal.percent}
              colorHex={getHexColor(
                expenseBudgetTotal.budgetTotal,
                expenseBudgetTotal.reconciledTotal,
                false,
                false
              )}
            />
            <Divider isVisibleOnMobile />
            <div className="mt-4 flex justify-between">
              <p className="text-xs font-bold text-gray-500">Budget</p>
              <p>{FormatMoney(expenseBudgetTotal.budgetTotal)}</p>
            </div>
            <div className="mt-4 flex justify-between">
              <p className="text-xs font-bold text-gray-500">Reconciled</p>
              <p>{FormatMoney(expenseBudgetTotal.reconciledTotal)}</p>
            </div>
          </div>
          <Divider isVisibleOnMobile />
          <div className="border px-4 py-2 rounded-lg mt-4 lg:flex bg-gray-100 border-gray-200 hidden">
            <p className="font-semibold flex items-center mr-auto">Net Total</p>
            <div className="border-r pr-3">
              <p className="text-xs font-bold text-gray-500">Budgeted</p>
              <p>
                {FormatMoney(
                  incomeBudgetTotal.budgetTotal - expenseBudgetTotal.budgetTotal
                )}
              </p>
            </div>
            <div className="pl-3 pr-3">
              <p className="text-xs font-bold text-gray-500">Reconciled</p>
              <p>
                {FormatMoney(
                  incomeBudgetTotal.reconciledTotal -
                    expenseBudgetTotal.reconciledTotal
                )}
              </p>
            </div>
            <div className="w-72 mr-9 ml-3 flex items-center pr-3"></div>
          </div>
          <div className="border rounded-lg p-4 mt-4 bg-gray-100 border-gray-200 lg:hidden">
            <p className="font-semibold mb-1">Net Total</p>
            <Divider isVisibleOnMobile />
            <div className="mt-4 flex justify-between">
              <p className="text-xs font-bold text-gray-500">Budget</p>
              <p>
                {FormatMoney(
                  incomeBudgetTotal.budgetTotal - expenseBudgetTotal.budgetTotal
                )}
              </p>
            </div>
            <div className="mt-4 flex justify-between">
              <p className="text-xs font-bold text-gray-500">Reconciled</p>
              <p>
                {FormatMoney(
                  incomeBudgetTotal.reconciledTotal -
                    expenseBudgetTotal.reconciledTotal
                )}
              </p>
            </div>
          </div>
          <Divider isVisibleOnMobile />
          <div className="border px-4 py-2 rounded-lg mt-4 lg:flex hidden">
            <p className="font-semibold flex items-center mr-auto">
              Unreconciled
            </p>
            <div className="border-r pr-3 mr-32">
              <p className="text-xs font-bold text-gray-500">
                Debits ({unreconciledData.debit.count})
              </p>
              <p>{FormatMoney(unreconciledData.debit.total)}</p>
            </div>
            <div className="mr-3">
              <p className="text-xs font-bold text-gray-500">
                Credits ({unreconciledData.credit.count})
              </p>
              <p>{FormatMoney(unreconciledData.credit.total)}</p>
            </div>
          </div>
          <div className="border rounded-lg p-4 mt-4 bg-white border-gray-200 lg:hidden">
            <p className="font-semibold mb-1">Unreconciled</p>
            <Divider isVisibleOnMobile />
            <div className="mt-4 flex justify-between">
              <p className="text-xs font-bold text-gray-500">
                Debits ({unreconciledData.debit.count})
              </p>
              <p>{FormatMoney(unreconciledData.debit.total)}</p>
            </div>
            <div className="mt-4 flex justify-between">
              <p className="text-xs font-bold text-gray-500">
                Credits ({unreconciledData.credit.count})
              </p>
              <p>{FormatMoney(unreconciledData.credit.total)}</p>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default ParentDashboard;

const BudgetSummaryRowLG = ({
  budgetData,
  percent,
  color,
}: {
  budgetData: BudgetSummaryRecord;
  percent: number;
  color: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border px-4 py-2 rounded-lg mt-4 hidden lg:block">
      <div key={budgetData.name} className="flex">
        <p className="font-semibold flex items-center mr-auto">
          {budgetData.name}
        </p>
        <div className="border-r pr-3">
          <p className="text-xs font-bold text-gray-500">Budgeted</p>
          <p>{FormatMoney(budgetData.budgetAmount)}</p>
        </div>
        <div className="border-r  pl-3 pr-3">
          <p className="text-xs font-bold text-gray-500">Reconciled</p>
          <p>{FormatMoney(budgetData.budgetReconciled)}</p>
        </div>
        <div className="w-72 mr-3 ml-3 flex items-center border-r pr-3">
          <ColorBar percent={percent} colorHex={color} />
        </div>
        <SnapIcon
          icon={isOpen ? "chevron-up-solid" : "chevron-down-solid"}
          color="blue"
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {isOpen &&
        budgetData.expandedData.map((ex) => {
          let percent = (ex.budgetReconciled / ex.budgetAmount) * 100;
          let color =
            ex.budgetReconciled >= ex.budgetAmount
              ? "#10B981"
              : ex.budgetReconciled < ex.budgetAmount
              ? "#3B82F6"
              : "#EF4444";
          return (
            <div key={ex.name} className="flex mt-4">
              <p className="font-semibold flex items-center mr-auto pl-4">
                {ex.name}
              </p>
              <div className="border-r pr-3">
                <p className="text-xs font-bold text-gray-500">Budgeted</p>
                <p>{FormatMoney(ex.budgetAmount)}</p>
              </div>
              <div className="border-r  pl-3 pr-3">
                <p className="text-xs font-bold text-gray-500">Reconciled</p>
                <p>{FormatMoney(ex.budgetReconciled)}</p>
              </div>
              <div className="w-72 mr-9 ml-3 flex items-center pr-3">
                <ColorBar percent={percent} colorHex={color} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

const BudgetSummaryRowSM = ({
  budgetData,
  percent,
  color,
}: {
  budgetData: BudgetSummaryRecord;
  percent: number;
  color: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="lg:hidden border rounded-lg p-4 mt-4">
      <p className="font-semibold mb-2">{budgetData.name}</p>
      <ColorBar percent={percent} colorHex={color} />
      <Divider isVisibleOnMobile />
      <div className="flex mt-4">
        <p className="text-xs font-bold text-gray-500 mr-auto">Budget</p>
        <p className="tetx-sm font-semibold">
          {FormatMoney(budgetData.budgetAmount)}
        </p>
      </div>
      <div className="flex mt-4">
        <p className="text-xs font-bold text-gray-500 mr-auto">Reconciled</p>
        <p className="tetx-sm font-semibold">
          {FormatMoney(budgetData.budgetReconciled)}
        </p>
      </div>
      <Divider isVisibleOnMobile />
      {isOpen &&
        budgetData.expandedData.map((ex) => {
          let exPercent = (ex.budgetReconciled / ex.budgetAmount) * 100;
          let color =
            ex.budgetReconciled >= ex.budgetAmount
              ? "#10B981"
              : ex.budgetReconciled < ex.budgetAmount
              ? "#3B82F6"
              : "#EF4444";
          return (
            <div key={ex.name} className="mt-2">
              <p className="font-semibold mb-2">{ex.name}</p>
              <ColorBar percent={exPercent} colorHex={color} />
              <Divider isVisibleOnMobile />
              <div className="flex mt-4">
                <p className="text-xs font-bold text-gray-500 mr-auto">
                  Budget
                </p>
                <p className="tetx-sm font-semibold">
                  {FormatMoney(ex.budgetAmount)}
                </p>
              </div>
              <div className="flex mt-4">
                <p className="text-xs font-bold text-gray-500 mr-auto">
                  Reconciled
                </p>
                <p className="tetx-sm font-semibold">
                  {FormatMoney(ex.budgetReconciled)}
                </p>
              </div>
              <Divider isVisibleOnMobile />
            </div>
          );
        })}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex text-blue-500 font-bold justify-end mt-3"
      >
        <p>Show {isOpen ? "Less" : "More"}</p>
        <SnapIcon icon={isOpen ? "chevron-up-solid" : "chevron-down-solid"} />
      </div>
    </div>
  );
};
