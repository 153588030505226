import { ReactNode } from "react";
import Modal from "react-modal";
import Spinner from "shared-components/spinner";
import { SnapButton, SnapIcon } from "suit";

if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export enum BtnState {
  BASE,
  DISABLED,
  HIDDEN,
}

export type BtnType = {
  text: string;
  onClick?: () => void;
  btnState?: BtnState;
  btnStyle?:
    | "danger"
    | "grouped"
    | "primary"
    | "secondary"
    | "success"
    | "tertiary"
    | "warning";
};

type ModalType = {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
  title: string;
  customStyle?: string;
  btn1?: BtnType;
  btn2?: BtnType;
  icon?:
    | "exclamation-solid"
    | "shield-check-solid"
    | "credit-card-solid"
    | "cash-solid"
    | "check-solid";
  iconColor?: "#FEF3C7" | "#FEE2E2" | "#D1FAE5" | string;
  banner?: ReactNode;
};

function FixedModal({
  children,
  isOpen,
  toggle,
  title,
  customStyle,
  btn1,
  btn2,
  icon,
  iconColor,
  banner,
}: ModalType) {
  return (
    <Modal
      className={`mx-4 mt-52 lg:mx-auto lg:w-[343px] lg:mt-[100px] bg-white rounded-lg ${customStyle}`}
      isOpen={isOpen}
      style={{
        content: {
          border: "none",
        },
        overlay: {
          zIndex: "99999",
          backgroundColor: "rgba(0,0,0,0.2)",
        },
      }}
    >
      <div className="h-full flex flex-col rounded-lg overflow-auto p-4">
        <div className="flex justify-end">
          <SnapIcon
            icon={"x-solid"}
            color={"#64748B"}
            onClick={toggle}
            className="cursor-pointer"
          />
        </div>
        <div className="flex flex-col items-center">
          {icon && (
            <SnapIcon
              icon={icon}
              color={
                iconColor === "#FEF3C7"
                  ? "orange"
                  : iconColor === "#FEE2E2"
                  ? "red"
                  : iconColor === "#D1FAE5"
                  ? "green"
                  : "blue"
              }
              bgColor={iconColor}
              state="rounded"
              className="mt-[-24px]"
            />
          )}
          <p className="font-medium text-lg text-center">{title}</p>
          {banner && banner}
          {children && (
            <div className="overflow-auto h-full pt-2">{children}</div>
          )}
          {btn1 && (
            <div className="mt-4 w-full">
              <SnapButton
                variant={btn1.btnStyle || "primary"}
                onClick={btn1.onClick}
                className="w-full"
                fullWidth
                disabled={btn1.btnState === BtnState.DISABLED}
              >
                {btn1.btnState === BtnState.DISABLED ? (
                  <Spinner className="w-14" />
                ) : (
                  <>{btn1.text}</>
                )}
              </SnapButton>
              {btn2 && (
                <SnapButton
                  variant={btn2.btnStyle || "tertiary"}
                  onClick={btn2.onClick}
                  className="mr-2 mt-4 w-full"
                  fullWidth
                  disabled={btn2.btnState === BtnState.DISABLED}
                >
                  {btn2.btnState === BtnState.DISABLED ? (
                    <Spinner className="w-14" />
                  ) : (
                    <>{btn2.text}</>
                  )}
                </SnapButton>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default FixedModal;
