import { useMutation } from "@apollo/client";
import SplitIOContext from "context/splitio-context";
import ToastContext from "context/toast-context";
import {
  SpendBankTransaction,
  SpendLegacyTransaction,
  TransactionNotes,
} from "graphql/generated";
import {
  TRANSACTION_NOTE_CREATE,
  TRANSACTION_NOTE_UPDATE,
} from "graphql/mutations/transactions";
import { GET_NOTES_BY_PAYMENT_ID } from "graphql/queries/transactions";
import { useContextStrict } from "helpers/context-strict";
import { useContext, useEffect, useState } from "react";
import { SnapButton } from "suit";

type TransactionManangeNotesCardProps = {
  transactionId:
    | SpendBankTransaction["id"]
    | SpendLegacyTransaction["id"]
    | undefined;
  notes: TransactionNotes[];
  setNotes: React.Dispatch<React.SetStateAction<TransactionNotes[]>>;
  action: string;
  canEditNotes: boolean;
};

function TransactionManangeNotesCard({
  transactionId,
  notes,
  action,
  setNotes,
  canEditNotes,
}: TransactionManangeNotesCardProps) {
  const [createNote, { loading: loadingCreate, data: createData }] =
    useMutation(TRANSACTION_NOTE_CREATE, {
      fetchPolicy: "network-only",
    });
  const [updateNote, { loading: loadingUpdate, data: updateData }] =
    useMutation(TRANSACTION_NOTE_UPDATE, {
      fetchPolicy: "network-only",
    });

  const { setToast } = useContextStrict(ToastContext);
  const split = useContext(SplitIOContext);
  const [se518_adding_notes_btn, setSe518_adding_notes_btn] = useState(false);

  useEffect(() => {
    if (split) {
      setSe518_adding_notes_btn(split.isTreatmentOn("SE-518-Adding-Notes-BTN"));
    }
  }, [split]);

  useEffect(() => {
    if (
      (se518_adding_notes_btn && !loadingCreate && createData) ||
      (se518_adding_notes_btn && !loadingUpdate && updateData)
    ) {
      setToast({
        message: "Note has been saved.",
        type: "success",
      });
    }
    // eslint-disable-next-line
  }, [
    loadingCreate,
    loadingUpdate,
    createData,
    updateData,
    se518_adding_notes_btn,
  ]);
  const handleSave = () => {
    notes.forEach((note) => {
      let input = {
        content: note.content,
        paymentId: transactionId,
      };
      if (action === "Create") {
        createNote({
          variables: {
            input,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_NOTES_BY_PAYMENT_ID,
              variables: { id: transactionId },
            },
          ],
        });
      } else {
        updateNote({
          variables: {
            spendTransactionNoteUpdateId: note.id,
            input,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_NOTES_BY_PAYMENT_ID,
              variables: { id: transactionId },
            },
          ],
        });
      }
    });
  };

  return (
    <>
      <p className="font-medium text-base text-gray-600 mb-4">
        Transaction Notes
      </p>
      {notes.map((note, idx) => {
        let content = note.content;
        return (
          <input
            disabled={!canEditNotes}
            type="text"
            key={(note.id ?? "") + idx}
            className="border-2 w-full rounded-lg pb-14 px-2 pt-2"
            placeholder="Write a note or memo about this transaction"
            value={content ?? ""}
            onChange={(e) => {
              let tempNotes = [...notes];
              let tempNote = {
                ...note,
                content: e.target.value,
              };
              tempNotes.splice(idx, 1, tempNote);
              setNotes(tempNotes);
            }}
          />
        );
      })}
      {canEditNotes && (
        <div className="mt-4 lg:flex  lg:justify-end">
          <SnapButton variant="primary" fullWidth onClick={handleSave}>
            {se518_adding_notes_btn
              ? "Save Note"
              : action === "Create"
              ? "Save Note"
              : "Save Changes"}
          </SnapButton>
        </div>
      )}
    </>
  );
}

export default TransactionManangeNotesCard;
