import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import ProgramContext from "context/program-context";
import { FormatMoney } from "helpers/format-money";
import { useContext, useState } from "react";
import { SnapSelectMenu } from "suit";
import { SpendOrgAchCredit } from "graphql/generated";
import InputMask from "shared-components/input-mask";
import { achInputErrors } from "types/errors";

type TransferCounterpartyProps = {
  counterpartyOptions: SnapSelectMenuOption[];
  achCreditInput: SpendOrgAchCredit;
  setACHCreditInput: React.Dispatch<React.SetStateAction<SpendOrgAchCredit>>;
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  achInputErrors: achInputErrors;
  setACHInputErrors: React.Dispatch<React.SetStateAction<achInputErrors>>;
};

function TransferCounterparty({
  counterpartyOptions,
  achCreditInput,
  setACHCreditInput,
  amount,
  setAmount,
  achInputErrors,
  setACHInputErrors,
}: TransferCounterpartyProps) {
  const program = useContext(ProgramContext);

  const [limit, setLimit] = useState("");

  return (
    <div>
      <p>
        Amount cannot exceed the daily limit of{" "}
        <span className="text-green-500 font-bold">
          {FormatMoney(program?.accountLimits?.ach?.limits.dailyCredit)}
        </span>
      </p>
      <div className="lg:grid grid-cols-2 gap-6 mt-4 flex flex-col mb-6">
        <SnapSelectMenu
          label="Payee"
          placeholder="Select Payee"
          selectMenuOptions={counterpartyOptions}
          error={achInputErrors.counterpartyError}
          onSnap-select-menu-updated={(e) => {
            const selectedItem = e.detail.find((item) => item.selected);
            setACHCreditInput({
              ...achCreditInput,
              counterpartyId: selectedItem?.value!,
              counterpartyName: selectedItem?.name!,
            });
            setACHInputErrors({
              ...achInputErrors,
              counterpartyError: false,
            });
          }}
        />
        <div className="lg:mt-0 mt-4">
          <InputMask
            amount={amount}
            setAmount={setAmount}
            hasError={achInputErrors.amountError}
            onChange={() => {
              setACHInputErrors({
                ...achInputErrors,
                amountError: false,
              });
            }}
          />
        </div>
      </div>
      <div className="mt-4">
        <p className="text-sm font-normal">Note</p>
        <textarea
          value={achCreditInput.note}
          className={`border-2 border-gray-200 w-full rounded-lg pb-28 px-4 pt-3 ${
            (achInputErrors.noteError || limit.length >= 81) && "border-red-500"
          }`}
          onChange={(e) => {
            if (e.target.value.length <= 80) {
              setLimit(e.target.value);
              setACHCreditInput({
                ...achCreditInput,
                note: e.target.value,
              });
              setACHInputErrors({
                ...achInputErrors,
                noteError: false,
              });
            }
          }}
        />
        <div className="flex justify-between">
          <p className="text-sm text-gray-500">Required</p>
          <p className="text-sm text-gray-500">Limit {limit.length} / 80</p>
        </div>
      </div>
    </div>
  );
}

export default TransferCounterparty;
