import { Maybe, SpendInvoice } from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import Divider from "shared-components/divider";
import { SnapBadge, SnapCheckboxButton } from "suit";
import { InvoiceSetOverview } from "types/collections";
import { SpendRosterChecked } from "types/group-roster";
import { SpinnerContainer } from "shared-components/spinner";
type CardsProps = {
  participants: SpendRosterChecked[];
  isAllRecordsSelected: boolean;
  handleNavigate: (userId: string) => void;
  handleCalculations: (invoice: Maybe<SpendInvoice>[]) => InvoiceSetOverview;
  handleCheckboxAction: (idx: number) => void;
  handleGroupNavigate: (groupId: string) => void;
  handleNavToReportingInvoices: (groupRosterId: string, status: string) => void;
  loading: boolean;
  getInviteStatusAndOptionCheck: (
    inviteStatus: string | undefined | null,
    inviteExpiresAt: string | undefined | null,
    groupRosterStatus: string | undefined | null
  ) => { status: string; addOption: boolean };
};

function Cards({
  participants,
  isAllRecordsSelected,
  handleCheckboxAction,
  handleNavigate,
  handleCalculations,
  handleGroupNavigate,
  handleNavToReportingInvoices,
  loading,
  getInviteStatusAndOptionCheck,
}: CardsProps) {
  return (
    <div className="lg:hidden">
      <SpinnerContainer loading={loading} />
      {!loading &&
        participants.map((p, idx) => {
          return (
            <div
              className="flex rounded-lg px-4 py-5 border mt-4 first:mt-0 last:mb-4"
              key={`participant_${p.id}_${idx}`}
            >
              <SnapCheckboxButton
                checked={p.isChecked}
                onClick={() => handleCheckboxAction(idx)}
              />
              <div className="w-full">
                <p
                  className="text-base text-blue-600 font-semibold"
                  onClick={() => handleNavigate(p.id ?? "")}
                >
                  {p.name}
                </p>
                {p.groupRosters?.map((roster, i: number) => {
                  const { status } = getInviteStatusAndOptionCheck(
                    roster?.invite?.status,
                    roster?.invite?.expiresAt,
                    roster?.status
                  );
                  const season = roster?.group?.seasons?.find(
                    (season) => season?.id === roster.seasonId
                  );

                  return (
                    <div className="mt-4" key={`${roster?.id}_${i}`}>
                      {i > 0 && <Divider isVisibleOnMobile className="mb-2" />}
                      <div className="flex">
                        <div>
                          <p className="text-sm mr-2">Group</p>
                        </div>
                        <div>
                          <p className="text-sm text-blue-600 font-bold mb-1">
                            {roster?.group?.name}
                          </p>
                          <SnapBadge
                            className="capitalize"
                            color={getBadgeColor(status || "")}
                          >
                            {status}
                          </SnapBadge>
                          <p className="text-sm font-medium mt-1">
                            {season?.name ||
                              `${FormatDate(
                                season?.startDateAt ?? "",
                                DateFormatSupported.NumbersLight
                              )} - ${FormatDate(
                                season?.endDateAt ?? "",
                                DateFormatSupported.NumbersLight
                              )}`}
                          </p>
                        </div>
                      </div>
                      <Divider isVisibleOnMobile />
                      <div className="flex justify-between py-2">
                        <p>Paid</p>
                        <p
                          className={`${
                            roster?.total?.paid &&
                            "text-green-600 cursor-pointer"
                          }`}
                          onClick={() => {
                            handleNavToReportingInvoices(
                              roster?.id ?? "",
                              "paid"
                            );
                          }}
                        >
                          {FormatMoney(roster?.total?.paid ?? 0)}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p>Processing</p>
                        <p>{FormatMoney(roster?.total?.processing ?? 0)}</p>
                      </div>
                      <div className="flex justify-between py-2">
                        <p>Upcoming</p>
                        <p
                          className={`${
                            roster?.total?.upcoming &&
                            "text-blue-600 cursor-pointer"
                          }`}
                          onClick={() => {
                            handleNavToReportingInvoices(
                              roster?.id ?? "",
                              "upcoming"
                            );
                          }}
                        >
                          {FormatMoney(roster?.total?.upcoming ?? 0)}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p>Past Due</p>
                        <p
                          className={`${
                            roster?.total?.paid && "text-red-600 cursor-pointer"
                          }`}
                          onClick={() => {
                            handleNavToReportingInvoices(
                              roster?.id ?? "",
                              "past_due"
                            );
                          }}
                        >
                          {FormatMoney(roster?.total?.pastDue ?? 0)}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default Cards;
