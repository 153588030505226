import { useMutation, useQuery } from "@apollo/client";
import {
  DropdownMenuItem,
  SnapSelectMenuOption,
} from "@snap-mobile/snap-ui/dist/types/utils";
import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import SeasonContext from "context/season-context";
import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import {
  SpendGroupRoster,
  SpendPaymentSchedule,
  useSpendGroupRostersBySeasonV2Query,
  useSpendPaymentScheduleRevertManyMutation,
  useSpendPaymentScheduleRevertMutation,
} from "graphql/generated";
import { CREATE_GROUP_ROSTERS } from "graphql/mutations/group";
import { UPDATE_PAYMENT_SCHEDULE } from "graphql/mutations/payment";
import { GET_GROUPS_FILTERED } from "graphql/queries/group";
import { toTitleCase } from "helpers/string";
import useModal from "hooks/use-modal";
import { SetStateAction, useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import ScrollableModal, {
  BtnState,
  BtnType,
} from "shared-components/modal/scrollable-modal";
import ShowingResults from "shared-components/showing-results";
import Sort from "shared-components/sort";
import { VerticalValueStyle } from "shared-components/vertical-label-value";
import { SnapActionSheet, SnapButton, SnapPagination } from "suit";
import { GroupRosterChecked, SpendRosterResend } from "types/group-roster";
import { LabelValueObject } from "types/label-value-object";
import {
  collectionActions,
  collectionData,
  collectionModalOptions,
} from "types/programs";
import { SpendPermissions } from "types/roles-permissions";
import Cards from "./card";
import AddParticipant from "./modals/add-participant";
import CancelPaymentScheduleEditModal from "./modals/payment-schedule/cancel-payment-schedule-edit";
import EditModeWarningPaymentScheduleModal from "./modals/payment-schedule/edit-mode-warning-payment-schedule";
import EditPaymentSchedule from "./modals/payment-schedule/edit-payment-schedule";
import ViewPaymentSchedule from "./modals/payment-schedule/view-payment-schedule";
import Table from "./table";
import { useSplitTreatments } from "@splitsoftware/splitio-react";
import { useContextStrict } from "helpers/context-strict";
import SplitIOContext from "context/splitio-context";
import { PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON } from "graphql/queries/payment-schedule";
import { FormatMoney, ParseMoney } from "helpers/format-money";
import { useSpendPagination } from "hooks/use-spend-pagination";
import PageLimit from "shared-components/page-limit";

type ParticipantRosterProps = {
  isCollectionOptionsOpen: boolean;
  setIsCollectionOptionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsItemsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalItemsChecked: React.Dispatch<React.SetStateAction<number>>;
  seasonRoster: collectionData[];
  setSeasonRoster: React.Dispatch<React.SetStateAction<collectionData[]>>;
  setSelectedSeasonRoster: React.Dispatch<
    React.SetStateAction<collectionData[]>
  >;
  setIsDefaultBulkAction: React.Dispatch<SetStateAction<boolean>>;
  setSelectedSeasonRosterForResend: React.Dispatch<SpendRosterResend[]>;
  paymentScheduleStatus: string;
  setPaymentScheduleStatus: React.Dispatch<React.SetStateAction<string>>;
  setPaymentScheduleLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSeasonRoster: collectionData[];
};
export type SpendPaymentScheduleWithFees = SpendPaymentSchedule & {
  received?: number;
  feeAmount?: number;
  hasError: boolean;
};
function ParticipantRoster({
  isCollectionOptionsOpen,
  setIsCollectionOptionsOpen,
  setIsItemsChecked,
  setTotalItemsChecked,
  seasonRoster,
  setSeasonRoster,
  setSelectedSeasonRoster,
  setIsDefaultBulkAction,
  setSelectedSeasonRosterForResend,
  paymentScheduleStatus,
  setPaymentScheduleStatus,
  setPaymentScheduleLoading,
  selectedSeasonRoster,
}: ParticipantRosterProps) {
  const Splits = useContextStrict(SplitIOContext);
  const [se579NotSavingPublish, setSe579NotSavingPublish] = useState(false);
  useEffect(() => {
    setSe579NotSavingPublish(Splits.isTreatmentOn("SE-579-Not-Saving-Publish"));
  }, [Splits]);

  const program = useContext(ProgramContext);
  const season = useContext(SeasonContext);
  const group = useContext(GroupContext);
  const activeGroup = group?.activeGroup;
  const toast = useContext(ToastContext);
  const display = useContext(DisplayContext);

  const {
    sort,
    toggleSort,
    sortValue: currentSort,
    handleSortValue,
  } = useSpendPagination();

  const canEditParticipant =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.groupRostersUpdate
    ) ?? false;

  const spendPercent = program?.getSpendPercent() || 0;
  const spendBaseFee = program?.getSpendBaseFee() || 0;
  const { isOpen, toggle } = useModal();
  const { isOpen: editModeWarningOpen, toggle: editModeWarningToggle } =
    useModal();
  const { isOpen: revertChangesOpen, toggle: revertChangesToggle } = useModal();

  const [selectedModalOption, setSelectedModalOption] = useState(0);
  const [savingData, setSavingData] = useState<GroupRosterChecked[]>([]);
  const [incomeCategories, setIncomeCategories] = useState<
    SnapSelectMenuOption[]
  >([]);
  const [invoices, setInvoices] = useState<SpendPaymentScheduleWithFees[]>([]);
  const [scheduleStatus, setScheduleStatus] = useState<
    "published" | "on hold" | "draft"
  >("published");
  const [isButtonClickable, setIsButtonClickable] = useState(true);
  const [allSelected, setAllSelected] = useState(false);
  const [page, setPage] = useState(0);
  const [isBtnActive, setIsBtnActive] = useState(true);
  const [pageLimit, setPageLimit] = useState(10);

  const { loading: loadingRoster, data: rosterData } =
    useSpendGroupRostersBySeasonV2Query({
      variables: {
        seasonId: season?.selectedSeason?.id ?? "",
        pagination: {
          offset: page * pageLimit,
          limit: pageLimit,
        },
        sort,
      },
      skip: !season?.selectedSeason?.id,
    });

  const { loading: loadingPaymentSchedule, data: paymentScheduleData } =
    useQuery(PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON, {
      variables: {
        seasonId: season?.selectedSeason?.id,
        groupIdOrSeasonId: season?.selectedSeason?.id,
      },
    });

  const [updatePSI, { data: psiData, loading: psiLoading }] = useMutation(
    UPDATE_PAYMENT_SCHEDULE,
    {
      refetchQueries: [
        "SpendGroupRostersBySeasonV2",
        {
          query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
          variables: {
            seasonId: season?.selectedSeason?.id,
            groupIdOrSeasonId: season?.selectedSeason?.id,
          },
          fetchPolicy: "network-only",
        },
        "SpendOrganizationGroupsCategories",
      ],
    }
  );
  const [cancelPSI] = useSpendPaymentScheduleRevertMutation({
    refetchQueries: [
      "SpendGroupRostersBySeasonV2",
      {
        query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
        variables: {
          seasonId: season?.selectedSeason?.id,
          groupIdOrSeasonId: season?.selectedSeason?.id,
        },
        fetchPolicy: "network-only",
      },
      "SpendOrganizationGroupsCategories",
    ],
  });
  const [cancelPSIMany] = useSpendPaymentScheduleRevertManyMutation({
    refetchQueries: [
      "SpendGroupRostersBySeasonV2",
      {
        query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
        variables: {
          seasonId: season?.selectedSeason?.id,
          groupIdOrSeasonId: season?.selectedSeason?.id,
        },
        fetchPolicy: "network-only",
      },
      "SpendOrganizationGroupsCategories",
    ],
  });

  const [createGroupRosters, { data: grMutateData, loading: grMutateLoading }] =
    useMutation(CREATE_GROUP_ROSTERS, {
      refetchQueries: [
        "SpendGroupRostersBySeasonV2",
        {
          query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
          variables: {
            seasonId: season?.selectedSeason?.id,
            groupIdOrSeasonId: season?.selectedSeason?.id,
          },
          fetchPolicy: "network-only",
        },
      ],
    });

  const { treatments, isReady } = useSplitTreatments({
    names: ["SE-469-payment-schedule-cancel-fix"],
  });

  const cancelPaymentInvoices = () => {
    if (treatments["SE-469-payment-schedule-cancel-fix"]?.treatment === "on") {
      const ids: string[] = invoices.reduce((acc, invoice) => {
        if (invoice.id) acc.push(invoice.id);
        return acc;
      }, [] as string[]);

      cancelPSIMany({
        variables: {
          spendPaymentScheduleRevertIds: ids,
        },
      });
    } else {
      if (isReady) {
        console.warn("Flag is not ready, fallback to previous logic");
      }
      invoices.forEach((invoice) => {
        if (invoice.id) {
          cancelPSI({
            variables: {
              spendPaymentScheduleRevertId: invoice.id,
            },
          });
        } else {
          console.log("Unable to cancel due to missing Id");
        }
      });
    }
  };
  const publishDraftPaymentInvoices = (status: "published" | "draft") => {
    invoices.forEach((i) => {
      const budgetItemId = i.budgetItemId;
      const foundBI = incomeCategories.find((ic) => ic.value === budgetItemId);
      if (!foundBI) {
        i.hasError = true;
      }
    });

    const hasErrors = invoices.some((i) => i.hasError);
    if (hasErrors) {
      toast?.setToastProps({
        message: "Invoices are missing budget items",
        type: "danger",
      });
      toast?.toggleToast();
    }

    setScheduleStatus(status);
    invoices.forEach((invoice) => {
      updatePSI({
        variables: {
          spendPaymentScheduleUpdateId: invoice.id,
          input: {
            status: status,
            seasonId: season?.selectedSeason?.id,
          },
        },
        refetchQueries: [
          "SpendGroupRostersBySeasonV2",
          {
            query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
            variables: {
              seasonId: season?.selectedSeason?.id,
              groupIdOrSeasonId: season?.selectedSeason?.id,
            },
            fetchPolicy: "network-only",
          },
          {
            query: GET_GROUPS_FILTERED,
            errorPolicy: "all",
            fetchPolicy: "network-only",
          },
          "SpendOrganizationGroupsCategories",
        ],
      });
    });
    setIsBtnActive(false);
    setSelectedSeasonRoster([]);
    setTotalItemsChecked(0);
    setIsItemsChecked(false);
    setSelectedSeasonRosterForResend([]);
    setAllSelected(false);
  };
  useEffect(() => {
    if (!psiLoading && psiData && psiData.spendPaymentScheduleUpdate) {
      toast &&
        toast.setToastProps({
          message:
            scheduleStatus === "published"
              ? "Payment schedule published."
              : "Payment schedule is on hold.",
          type: scheduleStatus === "published" ? "success" : "warning",
        });
      toast && toast.toggleToast();
      const currentSeasonUpdated = {
        ...group?.activeGroup?.currentSeason,
        paymentScheduleStatus: toTitleCase(
          scheduleStatus === "published"
            ? "published"
            : scheduleStatus === "draft"
            ? "on hold"
            : "draft"
        ),
      };
      group?.setAndStoreActiveGroup({
        ...group?.activeGroup,
        currentSeason: currentSeasonUpdated,
        latestSeason: currentSeasonUpdated,
      });
      setIsBtnActive(true);
      if (selectedModalOption === 2) {
        toggle();
      } else if (scheduleStatus === "draft") {
        setSelectedModalOption(2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psiData, psiLoading]);

  useEffect(() => {
    const CalculateFee = (amountDue: number): number => {
      return amountDue * spendPercent + spendBaseFee;
    };

    if (!loadingPaymentSchedule && paymentScheduleData?.spendPaymentSchedules) {
      const invoicesUnordered = paymentScheduleData.spendPaymentSchedules.map(
        (schedule: SpendPaymentSchedule) => {
          return {
            ...schedule,
            received:
              (schedule.amountDue ?? 0) - CalculateFee(schedule.amountDue ?? 0),
            feeAmount: CalculateFee(schedule?.amountDue ?? 0),
            hasError: false,
          };
        }
      );
      invoicesUnordered.sort((a: any, b: any) =>
        a.description > b.description ? 1 : -1
      );
      let holdStatus = invoicesUnordered.every(
        (invoice: SpendPaymentScheduleWithFees) => invoice.status === "draft"
      );
      let publishStatus = invoicesUnordered.every(
        (invoice: SpendPaymentScheduleWithFees) =>
          invoice.status === "published"
      );
      switch (true) {
        case holdStatus:
          setPaymentScheduleStatus("On Hold");
          break;
        case publishStatus:
          setPaymentScheduleStatus("Published");
          break;
        default:
          setPaymentScheduleStatus("Draft");
          break;
      }
      setInvoices(invoicesUnordered);
      if (se579NotSavingPublish) {
        setIsBtnActive(true);
      }
      setPaymentScheduleLoading(loadingPaymentSchedule);
    }
    // eslint-disable-next-line
  }, [loadingPaymentSchedule, paymentScheduleData, isOpen]);

  useEffect(() => {
    if (
      !loadingRoster &&
      rosterData?.spendGroupRostersBySeasonV2?.groupRosters
    ) {
      const rosterIds = new Set(selectedSeasonRoster.map(({ id }) => id));

      const rosters = rosterData.spendGroupRostersBySeasonV2.groupRosters.map(
        (grouproster: SpendGroupRoster | null) => {
          let isChecked = false;
          if (grouproster?.id) {
            isChecked = rosterIds.has(grouproster.roster?.id ?? "");
          }

          return {
            inviteId: grouproster?.invite?.id || "",
            inviteStatus: grouproster?.invite?.status ?? "No Invite Sent",
            isChecked,
            title: grouproster?.roster?.name || "",
            status1: grouproster?.isArchived
              ? "archived"
              : grouproster?.total?.statuses?.[0] || "n/a", // TODO: fix this later with handleCalculations()
            status2: "",
            paid: FormatMoney(grouproster?.total?.paid || 0),
            processing: FormatMoney(grouproster?.total?.processing || 0),
            upcoming: FormatMoney(grouproster?.total?.upcoming || 0),
            pastDue: FormatMoney(grouproster?.total?.pastDue || 0),
            credited: FormatMoney(grouproster?.total?.credited),
            hasWarning: false,
            email: grouproster?.roster?.email || "",
            id: grouproster?.rosterId || "",
            userId: grouproster?.userId || "",
            groupId: grouproster?.group?.id || "",
            seasonId: grouproster?.seasonId || "",
            groupName: grouproster?.group?.name || "",
            groupRosterId: grouproster?.id || "",
          };
        }
      );
      setSeasonRoster(rosters);
    }
    // eslint-disable-next-line
  }, [
    loadingRoster,
    rosterData,
    activeGroup,
    season?.selectedSeason,
    spendPercent,
    spendBaseFee,
    setSeasonRoster,
  ]);

  useEffect(() => {
    if (!grMutateLoading && grMutateData) {
      toggle && toggle();
      toast &&
        toast.setToastProps({
          message: "Successfully added participant(s)",
          type: "success",
        });
      toast && toast.toggleToast();
      setIsButtonClickable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grMutateData, grMutateLoading]);

  useEffect(() => {
    let rosterItems = seasonRoster.slice();
    let selected = rosterItems.filter((i) => i.isChecked);
    setAllSelected(
      rosterItems.length !== 0 && rosterItems.length === selected.length
    );
  }, [page, seasonRoster]);

  let collectionActions: collectionActions[] = [
    {
      label: "View Payment Schedule",
      onClick: () => {
        handleModalOptions(0);
      },
      className: "mt-2",
      btnStyle: "secondary",
    },
    canEditParticipant
      ? {
          label: "Add Participant",
          onClick: () => {
            handleModalOptions(1);
          },
          className: "mt-4",
          btnStyle: "primary",
        }
      : {
          label: "",
          onClick: () => {},
          className: "",
          btnStyle: "primary",
        },
  ];

  const modalOptions: collectionModalOptions[] = [
    {
      title: "Groups Payment Schedule",
      btn1: {
        text:
          paymentScheduleStatus === "Published"
            ? "Turn editing mode on"
            : "Continue editing",
        onClick: () => {
          if (paymentScheduleStatus === "Published") {
            editModeWarningToggle();
          } else {
            setSelectedModalOption(2);
          }
        },
        btnStyle: "primary",
        btnState: canEditParticipant ? BtnState.BASE : BtnState.HIDDEN,
      },
    },
    {
      title: "Add Participant to Group",
      btn1: {
        text: "Submit",
        onClick: () => {
          if (!isButtonClickable) {
            return;
          }
          if (savingData?.length === 0) {
            toast &&
              toast.setToastProps({
                message: "No participants added",
                type: "warning",
              });
            toast && toast.toggleToast();
            return;
          }

          const input = {
            groupId: activeGroup?.id,
            seasonId: season?.selectedSeason?.id,
            rosters: savingData.map((x) => {
              return {
                email: x.roster?.email?.toLowerCase(),
                name: x.roster?.name,
              };
            }),
          };
          if (input.groupId && input.seasonId) {
            setIsButtonClickable(false);
            createGroupRosters({ variables: { input } });
          } else {
            toast &&
              toast.setToastProps({
                message: "Something went wrong, please refresh the page.",
                type: "danger",
              });
            setIsButtonClickable(true);
            toast && toast.toggleToast();
          }
        },
        btnStyle: "primary",
        btnState: isButtonClickable ? BtnState.BASE : BtnState.DISABLED,
      },
    },
    {
      title: "Groups Payment Schedule",
      btn1: {
        text: "Publish",
        onClick: () => {
          publishDraftPaymentInvoices("published");
        },
        btnStyle: "primary",
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
      },
    },
  ];
  const [sortOptions] = useState<DropdownMenuItem[]>([
    {
      name: "Participant A - Z",
      text: "Participant A - Z",
      value: "participant:ASC",
    },
    {
      name: "Participant Z - A",
      text: "Participant Z - A",
      value: "participant:DESC",
    },
    {
      name: "Status A - Z",
      text: "Status A - Z",
      value: "status:ASC",
    },
    {
      name: "Status Z - A",
      text: "Status Z - A",
      value: "status:DESC",
    },
    {
      name: "Paid High to Low",
      text: "Paid High to Low",
      value: "paid:DESC",
    },
    {
      name: "Paid Low to High",
      text: "Paid Low to High",
      value: "paid:ASC",
    },
    {
      name: "Upcoming High to Low",
      text: "Upcoming High to Low",
      value: "upcoming:DESC",
    },
    {
      name: "Upcoming Low to High",
      text: "Upcoming Low to High",
      value: "upcoming:ASC",
    },
    {
      name: "Past Due High to Low",
      text: "Past Due High to Low",
      value: "pastDue:DESC",
    },
    {
      name: "Past Due Low to High",
      text: "Past Due Low to High",
      value: "pastDue:ASC",
    },
    {
      name: "Credited High to Low",
      text: "Credited High to Low",
      value: "credited:DESC",
    },
    {
      name: "Credited Low to High",
      text: "Credited Low to High",
      value: "created:ASC",
    },
  ]);
  let btn1: BtnType = modalOptions[selectedModalOption].btn1;
  let btn2: BtnType = {
    text: "Cancel",
    onClick: () => {
      if (selectedModalOption === 2) {
        revertChangesToggle();
      } else {
        toggle();
      }
    },
  };

  const prepRightData = (collection: collectionData, status: string) => {
    const rightData: LabelValueObject[] = [];
    rightData.push({
      key: "Status",
      value: status,
      valueStyle: VerticalValueStyle.Badge,
      className: "flex-col",
    });
    rightData.push({ key: "Paid", value: collection.paid });
    rightData.push({ key: "Processing", value: collection.processing });
    rightData.push({ key: "Upcoming", value: collection.upcoming });
    rightData.push({
      key: "Past Due",
      value: collection.pastDue,
      hasWarning: collection.hasWarning,
    });
    rightData.push({ key: "Credited", value: collection.credited });

    return rightData;
  };

  const handleSelectall = (
    selectedItems: collectionData[],
    selectAll: boolean,
    isChecked: boolean
  ) => {
    let tempSelectedItems = [...selectedSeasonRoster];
    let tempItems: collectionData[] = [...seasonRoster];

    selectedItems.forEach((item) => {
      let updatedItem = {
        ...item,
        isChecked: !item.isChecked,
      };

      if (selectAll) {
        updatedItem.isChecked = isChecked;
      }

      const foundItem = tempSelectedItems.find(
        (tempItem) => tempItem.id === item.id
      );

      if (!foundItem && updatedItem.isChecked) {
        tempSelectedItems.push(updatedItem);
      } else if (foundItem) {
        const idx = tempSelectedItems.findIndex(
          (item) => item.id === updatedItem.id
        );
        tempSelectedItems.splice(idx, 1);
      }

      const rosterIdx = tempItems.findIndex(
        (item) => item.id === updatedItem.id
      );
      tempItems.splice(rosterIdx, 1, updatedItem);
    });

    setSeasonRoster(tempItems);
    setSelectedSeasonRoster(tempSelectedItems);
    setTotalItemsChecked(tempSelectedItems.length);
    let resendInvitesArray = tempSelectedItems.map((invite) => {
      return {
        id: invite.inviteId,
        status: invite.inviteStatus,
        email: invite.email ?? "",
        userId: invite.userId ?? "",
        groupId: invite.groupId ?? "",
        seasonId: invite.seasonId ?? "",
        groupName: invite.groupName ?? "",
        isArchived: invite.status1 === "archived" ? true : false,
        groupRosterId: invite.groupRosterId,
      };
    });

    let isUsersArchived = tempSelectedItems.some(
      (roster) => roster.status1 === "archived"
    );
    let isUsersSignedUp = tempSelectedItems.some(
      (roster) => roster.status1 !== "archived"
    );
    let isUserNotSignedUp = tempSelectedItems.some((roster) => {
      const paid = ParseMoney(roster.paid);
      const processing = ParseMoney(roster.processing);
      const credited = ParseMoney(roster.credited);

      if (paid || processing || credited) {
        return false;
      } else if (roster.inviteId === null) {
        return true;
      } else {
        switch (roster.inviteStatus) {
          case "No Invite Sent":
          case "sent":
          case "pending":
          case "canceled":
          case "expired":
          case "created":
            return true;
          default:
            return false;
        }
      }
    });
    setIsDefaultBulkAction(
      isUserNotSignedUp || (isUsersSignedUp && isUsersArchived)
    );
    setSelectedSeasonRosterForResend(resendInvitesArray);
    setIsItemsChecked(tempSelectedItems.length > 0);
  };

  const handleModalOptions = (optionSelected: number) => {
    setSelectedModalOption(optionSelected);
    toggle();
  };
  const handleCollectionInviteStatus = (collectionData: collectionData) => {
    if (collectionData.status1 === "archived") {
      return "Archived";
    } else {
      switch (collectionData.inviteStatus?.toLowerCase()) {
        case "canceled":
          return "Canceled";
        case "pending":
        case "sent":
          return "Not Signed Up";
        case "accepted":
          return collectionData.status1.replace("_", " ");
        case "expired":
          return "Expired";
        default:
          return "No Invite Sent";
      }
    }
  };

  return (
    <div>
      <div className="flex">
        <p className="mr-auto self-center text-lg font-semibold">
          Participants Roster
        </p>
        <div className="lg:flex hidden">
          <SnapButton
            variant="secondary"
            className="mr-2"
            onClick={() => {
              handleModalOptions(0);
            }}
            disabled={loadingPaymentSchedule}
          >
            View Payment Schedule
          </SnapButton>
          <SnapButton
            hidden={!canEditParticipant || group?.isArchived}
            variant="primary"
            onClick={() => {
              handleModalOptions(1);
            }}
            disabled={loadingPaymentSchedule}
          >
            + Add Participant
          </SnapButton>
        </div>
        {isCollectionOptionsOpen && (
          <SnapActionSheet
            header="Collection's Actions"
            onClick={() => setIsCollectionOptionsOpen(false)}
          >
            <Divider isVisibleOnMobile className="mt-0" />
            {collectionActions.map((actionItem, idx) => {
              return (
                <div
                  className="mx-4 mt-4"
                  key={idx}
                  onClick={actionItem.onClick}
                >
                  <p>{actionItem.label}</p>
                </div>
              );
            })}
          </SnapActionSheet>
        )}
      </div>
      <div className="lg:flex mt-4">
        <ShowingResults
          totalNumOfResults={
            rosterData?.spendGroupRostersBySeasonV2?.count ||
            seasonRoster.length
          }
          numOfResultsBeingDisplayed={
            (rosterData?.spendGroupRostersBySeasonV2?.count ?? 0) <= 10
              ? seasonRoster.length
              : pageLimit * page + pageLimit >=
                (rosterData?.spendGroupRostersBySeasonV2?.count ?? 0)
              ? rosterData?.spendGroupRostersBySeasonV2?.count ?? 0
              : pageLimit * page + pageLimit
          }
          startingNumOfResults={
            seasonRoster.length === 0 ? 0 : pageLimit * page + 1
          }
          hasCheckbox
          checkboxAction={(e) =>
            handleSelectall(seasonRoster, true, e.target.checked)
          }
          isNameBoxChecked={allSelected}
          hideCheckboxOnWeb
        />
        <PageLimit
          setPageLimit={setPageLimit}
          setPage={setPage}
          localStorageName={"collections-page-limit"}
        />
      </div>
      <Divider isVisibleOnMobile className="my-5 lg:hidden" />
      <Sort
        selectedSortOption={currentSort}
        options={sortOptions}
        handleSort={(e) => handleSortValue(e.value)}
      />
      {display?.isMobile ? (
        <Cards
          collectionData={seasonRoster}
          handleSelectall={handleSelectall}
          prepRightData={prepRightData}
          allSelected={allSelected}
          canEditParticipant={canEditParticipant}
          isArchived={group?.isArchived!}
          page={page}
          queryLoading={loadingRoster}
          handleCollectionInviteStatus={handleCollectionInviteStatus}
        />
      ) : (
        <Table
          collectionData={seasonRoster}
          handleSelectall={handleSelectall}
          allSelected={allSelected}
          canEditParticipant={canEditParticipant}
          isArchived={group?.isArchived!}
          page={page}
          queryLoading={loadingRoster}
          handleCollectionInviteStatus={handleCollectionInviteStatus}
          sort={sort}
          toggleSort={toggleSort}
        />
      )}
      <SnapPagination
        currentPage={page}
        itemCount={rosterData?.spendGroupRostersBySeasonV2?.count || 0}
        pageSize={pageLimit}
        onSnap-pagination-page-changed={(e) => setPage(e.detail)}
      />

      <ScrollableModal
        isOpen={isOpen}
        toggle={() => {
          toggle();
          setIsBtnActive(true);
        }}
        title={modalOptions[selectedModalOption].title}
        btn1={
          !group?.isArchived
            ? btn1
            : {
                text: "Cancel",
                btnStyle: "tertiary",
                onClick: toggle,
              }
        }
        btn2={!group?.isArchived ? btn2 : undefined}
      >
        {
          {
            0: (
              <ViewPaymentSchedule
                invoices={invoices}
                paymentScheduleStatus={paymentScheduleStatus}
              />
            ),
            1: (
              <AddParticipant
                saveData={(list) => setSavingData(list)}
                seasonRoster={seasonRoster}
              />
            ),
            2: (
              <EditPaymentSchedule
                invoices={invoices}
                setInvoices={setInvoices}
                incomeCategories={incomeCategories}
                setIncomeCategories={setIncomeCategories}
                setIsBtnActive={setIsBtnActive}
                se579NotSavingPublish={se579NotSavingPublish}
              />
            ),
          }[selectedModalOption]
        }
      </ScrollableModal>
      <EditModeWarningPaymentScheduleModal
        isOpen={editModeWarningOpen}
        toggle={editModeWarningToggle}
        groupName={activeGroup?.name ?? null}
        publishDraftPaymentInvoices={publishDraftPaymentInvoices}
      />
      <CancelPaymentScheduleEditModal
        isOpen={revertChangesOpen}
        toggle={revertChangesToggle}
        proceedAction={() => {
          selectedModalOption === 2 && publishDraftPaymentInvoices("draft");
          toggle();
        }}
        cancelAction={() => {
          cancelPaymentInvoices();
          revertChangesToggle();
          toggle();
        }}
      />
    </div>
  );
}

export default ParticipantRoster;
