import { useMutation } from "@apollo/client";
import ProgramContext from "context/program-context";
import UserContext from "context/user-context";
import {
  ORG_SIGNUP_AGREEMENT_CREATE,
  UPDATE_ORG_SETTINGS,
} from "graphql/mutations/organization";
import { GET_ORGANIZATION_SETTINGS } from "graphql/queries/organization";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import FixedModal from "shared-components/fixed-modal/fixed-modal";
import {
  SnapAlert,
  SnapAlertDescription,
  SnapAlertTitle,
  SnapButton,
  SnapIcon,
  SnapLabeledToggle,
  SnapLink,
} from "suit";
import { SpendPermissions } from "types/roles-permissions";

function SignUpAgreement() {
  const program = useContext(ProgramContext);
  const [
    createSignupAgreement,
    { loading: loadingAgreement, data: agreementData },
  ] = useMutation(ORG_SIGNUP_AGREEMENT_CREATE);
  const [updateSettings] = useMutation(UPDATE_ORG_SETTINGS);

  const [type, setType] = useState("new");
  const [signUpAgreementOpen, setSignUpAgreementOpen] = useState(false);
  const [agreementName, setAgreementName] = useState("");
  const [agreementContent, setAgreementContent] = useState("");
  const [turnEditingOn, setTurnEditingOn] = useState(false);
  const [createdAt, setCreatedAt] = useState("");
  const { isOpen, toggle } = useModal();
  const canEditSignUp =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.programSettingsUpdate
    ) ?? false;

  useEffect(() => {
    if (agreementData && agreementData.spendSignupAgreementCreate) {
      setType("edit");
      setTurnEditingOn(false);
    }
  }, [loadingAgreement, agreementData]);

  useEffect(() => {
    if (program?.settings?.signUpAgreement) {
      let agreement = program?.settings?.signUpAgreement;
      setCreatedAt(agreement.createdAt ?? "");
      setSignUpAgreementOpen(true);
      setAgreementName(agreement.name ?? "");
      setAgreementContent(agreement.content ?? "");
      setType("edit");
    }
  }, [program]);

  const handleCreateAgreement = () => {
    createSignupAgreement({
      variables: {
        input: {
          content: agreementContent,
          name: agreementName,
        },
      },
      refetchQueries: [
        { query: GET_ORGANIZATION_SETTINGS, fetchPolicy: "network-only" },
      ],
      fetchPolicy: "network-only",
    });
  };

  const signUpAgreementToggle = () => {
    if (signUpAgreementOpen) {
      toggle();
    } else {
      if (type === "new") {
        setTurnEditingOn(true);
      }
      let settings = program?.settings;
      updateSettings({
        variables: {
          input: {
            enableProgramAgreements: true,
            notifyBankActivityAdmins: settings?.notifyBankActivityAdmins,
            notifyDueFrequencies: settings?.notifyDueFrequencies,
            notifyFailedAchAdmins: settings?.notifyFailedAchAdmins,
            notifyPastDueAdmins: settings?.notifyPastDueAdmins,
            notifyPastDueNonUsers: settings?.notifyPastDueNonUsers,
            notifyUpcomingNonUsers: settings?.notifyUpcomingNonUsers,
          },
        },
      });
    }
    setSignUpAgreementOpen(!signUpAgreementOpen);
  };
  const handleCancel = () => {
    let settings = program?.settings;
    if (type === "new") {
      setAgreementName("");
      setAgreementContent("");
      setSignUpAgreementOpen(false);
      updateSettings({
        variables: {
          input: {
            enableProgramAgreements: false,
            notifyBankActivityAdmins: settings?.notifyBankActivityAdmins,
            notifyDueFrequencies: settings?.notifyDueFrequencies,
            notifyFailedAchAdmins: settings?.notifyFailedAchAdmins,
            notifyPastDueAdmins: settings?.notifyPastDueAdmins,
            notifyPastDueNonUsers: settings?.notifyPastDueNonUsers,
            notifyUpcomingNonUsers: settings?.notifyUpcomingNonUsers,
          },
        },
      });
    } else {
      setAgreementName(settings?.signUpAgreement?.name ?? "");
      setAgreementContent(settings?.signUpAgreement?.content ?? "");
      setTurnEditingOn(false);
    }
  };

  return (
    <div className="wrapper">
      <div className="card">
        <p className="text-lg font-semibold">Sign-up Agreement</p>
        <div
          className={`mt-4 border border-gray-200 rounded-lg p-2 lg:py-4 lg:px-6 ${
            turnEditingOn && "border-0 lg:border"
          }`}
        >
          <div className="lg:flex">
            <SnapLabeledToggle
              disabled={!canEditSignUp}
              checked={signUpAgreementOpen}
              label="Allow programs to require sign-up agreement."
              onClick={!canEditSignUp ? undefined : signUpAgreementToggle}
            />
            <SnapLink
              text="Learn More"
              href="https://snap-mobile.zendesk.com/hc/en-us/articles/19253714543131-Adding-a-Financial-Commitment-Agreement-"
              target="_blank"
              size="sm"
              className="font-medium ml-16 pl-3 lg:ml-4 lg:pl-0"
            />
          </div>
          {signUpAgreementOpen && (
            <div className="flex flex-col">
              <Divider isVisibleOnMobile className="mb-4 lg:mb-6" />
              {turnEditingOn && type === "edit" && (
                <SnapAlert type="info" className="mb-6">
                  <SnapAlertTitle>
                    This will archive the existing agreement and create a new
                    agreement
                  </SnapAlertTitle>
                  <SnapAlertDescription>
                    <ul className="list-disc">
                      <li>
                        Click "Save New Agreement" to save a new agreement
                      </li>
                      <li>
                        Click "Cancel" to return tothe existing agreement
                        (Changes will not be saved).
                      </li>
                    </ul>
                  </SnapAlertDescription>
                </SnapAlert>
              )}
              <div
                className={`${!turnEditingOn && "mt-0"} flex justify-between`}
              >
                {type === "edit" && (
                  <p className="text-sm font-medium text-gray-600 self-center">
                    Date Created:
                    <span className="text-gray-800 ml-4">
                      {FormatDate(createdAt, DateFormatSupported.Numbers)}
                    </span>
                  </p>
                )}
                {!turnEditingOn && canEditSignUp && (
                  <>
                    <SnapButton
                      hidden={!canEditSignUp}
                      variant="primary"
                      className="lg:block hidden"
                      onClick={() => setTurnEditingOn(true)}
                    >
                      Edit Agreement
                    </SnapButton>
                    <SnapButton
                      hidden={!canEditSignUp}
                      variant="primary"
                      className="lg:hidden"
                      icon="pencil-solid"
                      onClick={() => setTurnEditingOn(true)}
                    ></SnapButton>
                  </>
                )}
              </div>
              {turnEditingOn ? (
                <>
                  <p className="text-sm font-medium">Agreement Name</p>
                  <input
                    type="text"
                    className="border-2 rounded-lg mt-2 py-1 px-3"
                    value={agreementName}
                    onChange={(e) => setAgreementName(e.target.value)}
                  />
                  <p className="text-sm font-medium mt-4 lg:mt-6">
                    Agreement Content
                  </p>
                  <textarea
                    className="border-2 rounded-lg mt-2 pt-1 pb-16 px-3 w-full flex break-all flex-wrap"
                    value={agreementContent}
                    onChange={(e) => setAgreementContent(e.target.value)}
                  />
                </>
              ) : (
                <>
                  <p className="mt-6">Agreement Name</p>
                  <div className="flex justify-between border border-gray-200 rounded-lg py-1 px-3 bg-gray-100 mt-1">
                    <p>{agreementName}</p>
                    <SnapIcon
                      icon="lock-closed-solid"
                      size="sm"
                      color="#94A3B8"
                    />
                  </div>
                  <p className="mt-6">Agreement Content</p>
                  <div className="flex justify-between border border-gray-200 rounded-lg pt-2 pb-10 px-3 bg-gray-100 mt-1">
                    <p>{agreementContent}</p>
                    <SnapIcon
                      icon="lock-closed-solid"
                      size="sm"
                      color="#94A3B8"
                      className="items-start"
                    />
                  </div>
                </>
              )}
              {turnEditingOn && (
                <div className="lg:flex hidden mt-4 justify-end">
                  <SnapButton
                    variant="tertiary"
                    className="mr-4"
                    onClick={handleCancel}
                  >
                    Cancel
                  </SnapButton>
                  <SnapButton variant="primary" onClick={handleCreateAgreement}>
                    Save New Agreement
                  </SnapButton>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {signUpAgreementOpen && turnEditingOn && (
        <div className="lg:hidden flex w-full fixed bottom-14 bg-white px-6 py-5">
          <SnapButton
            variant="tertiary"
            fullWidth
            onClick={handleCancel}
            className="w-full"
          >
            Cancel
          </SnapButton>
          <SnapButton
            variant="primary"
            fullWidth
            onClick={handleCreateAgreement}
            className="w-full ml-4"
          >
            Save New Agreement
          </SnapButton>
        </div>
      )}
      <FixedModal
        isOpen={isOpen}
        toggle={toggle}
        title="Turn off the Sign-up Agreement"
        btn1={{
          text: "Turn off",
          btnStyle: "danger",
          onClick: () => {
            let settings = program?.settings;
            updateSettings({
              variables: {
                input: {
                  enableProgramAgreements: false,
                  notifyBankActivityAdmins: settings?.notifyBankActivityAdmins,
                  notifyDueFrequencies: settings?.notifyDueFrequencies,
                  notifyFailedAchAdmins: settings?.notifyFailedAchAdmins,
                  notifyPastDueAdmins: settings?.notifyPastDueAdmins,
                  notifyPastDueNonUsers: settings?.notifyPastDueNonUsers,
                  notifyUpcomingNonUsers: settings?.notifyUpcomingNonUsers,
                },
              },
            });
            toggle();
          },
        }}
        btn2={{
          text: "Cancel",
          btnStyle: "tertiary",
          onClick: () => {
            setSignUpAgreementOpen(true);
            toggle();
          },
        }}
      >
        <div>
          <p className="text-sm text-gray-500 text-center mt-2">
            Are you sure you want to turn it off? All of your data will be saved
            on the server. This action can be undone.
          </p>
        </div>
      </FixedModal>
    </div>
  );
}

export default SignUpAgreement;
