import { useLazyQuery } from "@apollo/client";
import SplitIOContext from "context/splitio-context";
import {
  CREATE_BEARER_TOKEN,
  CREATE_VERIFICATION_TOKEN,
} from "graphql/queries/unit";
import { useContextStrict } from "helpers/context-strict";
import { setAuthTokenData } from "helpers/get-auth-token-data";
import useToast from "hooks/use-toast";
import { createRef, useEffect, useState } from "react";
import FixedModal, {
  BtnState,
} from "shared-components/fixed-modal/fixed-modal";
import ToastMessage from "shared-components/toast-message";
import { SnapAlert, SnapAlertDescription, SnapAlertTitle } from "suit";

type AuthorizationProps = {
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  user: { id: string; name: string };
};

function Authorization({
  isAuthorized,
  setIsAuthorized,
  setIsLoading,
  user,
}: AuthorizationProps) {
  const Splits = useContextStrict(SplitIOContext);
  const [createVerificationToken, responseStuff] = useLazyQuery(
    CREATE_VERIFICATION_TOKEN,
    { fetchPolicy: "network-only" }
  );
  const [createBearer, { data: TokenData, error, loading: loadingBearer }] =
    useLazyQuery(CREATE_BEARER_TOKEN);
  const inputRef = createRef<HTMLInputElement>();

  const { isToastOpen, toggleToast, ...toast } = useToast(
    "Unable to Authenticate",
    "danger"
  );
  const [focused, setFocused] = useState(false);
  const [codeLength] = useState(new Array(6).fill(0));
  const [valArr, setValArr] = useState("");
  const [tokenToMatch, setTokenToMatch] = useState("");
  const [btnState, setBtnState] = useState<BtnState>(BtnState.BASE);

  const selectedIndex =
    valArr.length < codeLength.length ? valArr.length : codeLength.length - 1;
  const hideInput = !(valArr.length <= codeLength.length);
  useEffect(() => {
    setSe698Flag(Splits.isTreatmentOn("SE-698-Performance-Banner"));
    setOutageFlag(Splits.isTreatmentOn("thirdparty-outage-banner"));
  }, [Splits]);
  const [se698Flag, setSe698Flag] = useState(false);
  const [outageFlag, setOutageFlag] = useState(false);
  useEffect(() => {
    setIsLoading(false);
    createVerificationToken();
  }, [createVerificationToken, setIsLoading]);

  useEffect(() => {
    if (!responseStuff.loading && responseStuff.error) {
      if (isToastOpen) {
        toggleToast();
      }

      switch (responseStuff.error.message) {
        case "Missing Phone Number":
          toast?.setToastProps({
            message:
              "Your Snap! account is missing a phone number, please edit your profile and add one",
            type: "danger",
          });
          toggleToast();
          break;
        case "Unable to create verification token. Unable to send verification":
          toast?.setToastProps({
            message:
              "You have reached maximum attempts. Please wait 10 minutes before sending a new code.",
            type: "danger",
          });
          toggleToast();
          break;
      }
      setBtnState(BtnState.BASE);
    }

    if (responseStuff.data && !responseStuff.loading) {
      setTokenToMatch(
        responseStuff.data.spendUserVerificationToken.verificationToken
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseStuff.data, responseStuff.loading]);

  useEffect(() => {
    if (error) {
      if (isToastOpen) {
        toggleToast();
      }
      switch (error.message) {
        case "Unable to verify token. Verification check failed":
          toast?.setToastProps({
            message: "Code is incorrect please re-enter",
            type: "danger",
          });
          toggleToast();
          break;
        case "Unable to verify token. Invalid verificationCode length, at least four digits required":
          toast?.setToastProps({
            message: "Verification code must be at least four digits",
            type: "danger",
          });
          toggleToast();
          break;
        default:
          toast?.setToastProps({
            message: error.message,
            type: "danger",
          });
          toggleToast();
      }
      setBtnState(BtnState.BASE);
    }
    const hash = btoa(user.id);
    if (TokenData && TokenData.spendUserAccountBearerToken) {
      const tokenData = {
        token: TokenData.spendUserAccountBearerToken.token,
        tokenExpires: new Date(new Date().getTime() + 86400000),
        hex: hash ?? undefined,
      };

      setAuthTokenData(tokenData);
      setIsAuthorized(true);
      setBtnState(BtnState.BASE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingBearer, TokenData, error, setIsAuthorized]);

  const handleClick = () => {
    inputRef.current !== null && inputRef.current.focus();
  };
  const handleFocus = () => {
    setFocused(!focused);
  };
  const handleBlur = () => {
    setFocused(!focused);
  };

  const handleChange = (e: any) => {
    if (valArr.length <= codeLength.length) {
      setValArr((valArr + e.target.value).substring(0, codeLength.length));
    }
  };

  const handleKeyUp = (e: any) => {
    if (e.key === "Backspace") {
      setValArr(valArr.slice(0, valArr.length - 1));
    }
    if (e.key === "Enter") {
      VerifyToken();
    }
  };

  const VerifyToken = () => {
    setBtnState(BtnState.DISABLED);
    createBearer({
      variables: {
        input: {
          verificationCode: valArr,
          verificationToken: tokenToMatch,
        },
      },
    });
    setValArr("");
  };

  const handleRedirect = () => {
    window.location.replace(`${process.env.REACT_APP_SSO_HOST}` || "");
  };

  const numberInputOnWheelPreventChange = (e: any) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <FixedModal
      isOpen={!isAuthorized}
      toggle={() => {
        handleRedirect();
        setIsAuthorized(false);
      }}
      title={"2-Step Verification"}
      icon="shield-check-solid"
      iconColor="#DBEAFE"
      btn1={{
        text: "Confirm",
        onClick: () => {
          VerifyToken();
        },
        btnState: btnState,
      }}
      btn2={{
        text: "Cancel",
        onClick: handleRedirect,
      }}
      banner={<>
        {se698Flag && (
          <SnapAlert type="warning" className="mt-1">
            <SnapAlertTitle>
              We are experiencing temporary delays with site and banking
              functionality.
            </SnapAlertTitle>
            <SnapAlertDescription>
              We hope to have this resolved as quickly as possible. Please
              contact{" "}
              <a
                href="mailTo:spend.support@onsnap.com"
                target="_blank"
                rel="noreferrer"
                className="font-bold"
                style={{ color: "rgb(29, 78, 216)" }}
              >
                spend.support@onsnap.com
              </a>{" "}
              with any questions.
            </SnapAlertDescription>
          </SnapAlert>
        )}
        {outageFlag && (
          <SnapAlert type="danger" className="mt-1">
          <SnapAlertTitle>
            We are experiencing a third-party outage. We apologize for any inconveniece this may cause.
          </SnapAlertTitle>
          <SnapAlertDescription>
            We hope for this to be resolved as quickly as possible. Please
            contact{" "}
            <a
              href="mailTo:spend.support@onsnap.com"
              target="_blank"
              rel="noreferrer"
              className="font-bold"
              style={{ color: "rgb(29, 78, 216)" }}
            >
              spend.support@onsnap.com
            </a>{" "}
            with any questions.
          </SnapAlertDescription>
        </SnapAlert>
        )}
        </>
      }
    >
      <div>
        {isToastOpen && (
          <ToastMessage
            message={toast.message}
            isToastOpen={isToastOpen}
            toggleToast={toggleToast}
            type={toast.type}
          />
        )}
        <p className="text-center text-sm text-gray-500">
          We've sent a verification code to the phone number attached to your
          account.
        </p>
        <p className="mt-4 mb-2 text-sm font-medium text-gray-700">
          Verification Code
        </p>
        <div className="flex relative" onClick={handleClick}>
          {codeLength.map((v, index) => {
            return (
              <div
                key={index}
                className="border-2 border-gray-300 h-10 w-10 rounded-lg mr-3 relative text-center pt-1.5"
              >
                {valArr[index]}
                <div className="absolute box-shadow+" />
              </div>
            );
          })}
          <input
            value=""
            id="input"
            type="number"
            ref={inputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => handleChange(e)}
            onKeyUp={(e) => handleKeyUp(e)}
            onWheel={(e) => numberInputOnWheelPreventChange(e)}
            className={`absolute bg-transparent w-full h-10 opacity-0 left-${
              selectedIndex * 54
            }px`}
            autoFocus
            style={{
              width: "32px",
              top: "0px",
              bottom: "0px",
              left: `${selectedIndex * 54}px`,
              opacity: hideInput ? 0 : 1,
            }}
          />
        </div>
        <p className="text-sm text-gray-500 mt-2">
          Please enter your 6-digit verification code.
        </p>
        <p
          className="mt-2 cursor-pointer text-base text-blue-600 font-bold"
          onClick={() => {
            createVerificationToken();
            toast?.setToastProps({
              message: "Code Resent",
              type: "success",
            });
            toggleToast();
          }}
        >
          Send New Code
        </p>
      </div>
    </FixedModal>
  );
}

export default Authorization;
