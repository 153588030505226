import { useContext, useEffect, useRef, useState } from "react";
import Divider from "shared-components/divider";
import { SnapButton, SnapIcon, SnapLink, SnapInput } from "suit";
import AddParticipantCard from "./card";
import AddParticipantTable from "./table";
import {
  Maybe,
  SpendRoster,
  useSpendRostersFilteredLazyQuery,
} from "graphql/generated";
import { GroupRosterChecked } from "types/group-roster";
import { ValidateEmail } from "helpers/validation";
import { emptyStringCheck } from "helpers/empty-string-check";
import GroupContext from "context/group-context";
import ToastContext from "context/toast-context";
import DisplayContext from "context/display-context";
import { collectionData } from "types/programs";
type AddParticipantProps = {
  saveData: (list: GroupRosterChecked[]) => void;
  seasonRoster: collectionData[];
};

const GroupRosterValidator = {
  validate: (roster: Partial<SpendRoster>) => {
    let errors: string[] = [];
    if (emptyStringCheck(roster.name)) {
      errors.push("Empty name not permitted.");
    }
    if (!roster.email || !ValidateEmail(roster.email)) {
      errors.push("Invalid email address.");
    }

    return errors;
  },
};

function AddParticipant({ saveData, seasonRoster }: AddParticipantProps) {
  const Display = useContext(DisplayContext);
  const Group = useContext(GroupContext);
  const Toast = useContext(ToastContext);
  const [previewData, setPreviewData] = useState<GroupRosterChecked[]>([]);
  const [rostersQuery, { data, loading }] = useSpendRostersFilteredLazyQuery(
    {}
  );
  const addPreviewData = (newData: GroupRosterChecked[]) => {
    const makeIdent = (roster?: Maybe<SpendRoster>) => {
      return `${roster?.name}${roster?.email}`;
    };
    const existingNameAndEmails = new Set(
      previewData.map((d) => makeIdent(d.roster))
    );
    const newPreviewData = newData.reduce((acc, d) => {
      if (!d.roster) return acc;
      const errors = GroupRosterValidator.validate(d.roster);
      if (!errors.length && !existingNameAndEmails.has(makeIdent(d.roster))) {
        existingNameAndEmails.add(makeIdent(d.roster));
        acc.push(d);
      } else {
        // TODO: throw error or display errors;
        console.log(errors);
      }
      return acc;
    }, [] as GroupRosterChecked[]);

    setPreviewData([...previewData, ...newPreviewData]);
    return newPreviewData?.length;
  };

  const previewListElm = useRef<HTMLDivElement>(null);
  const focusToPreviewList = () => {
    setTimeout(() => {
      previewListElm.current && previewListElm.current.scrollIntoView();
      // TODO: we can do animation to blink on new item
    }, 100);
  };

  const [formData, setFormData] = useState({ name: "", email: "" });
  const [formError, setFormError] = useState<string | undefined>();
  const [rosterSelectable, setRosterSelectable] = useState<
    GroupRosterChecked[]
  >([]);
  const [isSelectFromExistingExpanded, setIsSelectFromExistingExpanded] =
    useState(false);
  const [isBulkUploadFormExpanded, setIsBulkUploadFormExpanded] =
    useState(false);
  const [participantBatchValues, setParticipantBatchValues] = useState("");
  const [existingSearchText, setExistingSearchText] = useState("");
  useEffect(() => {
    rostersQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!loading && data?.spendRostersFiltered) {
      if (data.spendRostersFiltered.rosters) {
        const dataList = data.spendRostersFiltered.rosters
          .filter((r) => r != null)
          .filter((r) =>
            r!.groupRosters?.every(
              (gr) => gr && gr.groupId !== Group?.activeGroup?.id
            )
          );

        setRosterSelectable(
          dataList.map((r) => ({
            roster: {
              name: r!.name,
              id: r!.id,
              email: r!.email,
            },
            group: {
              name: r!.groupRosters?.at(0)?.group?.name || "n/a",
            },
            isChecked: false,
          }))
        );
      }
    }
  }, [Group?.activeGroup?.id, data, loading]);
  useEffect(() => {
    saveData(previewData);
  }, [previewData, saveData]);

  const onGroupRosterSelect = (index: number) => {
    const selected = rosterSelectable.at(index);
    if (selected) {
      selected.isChecked = !selected?.isChecked;
      rosterSelectable.splice(index, 1, selected);
      setRosterSelectable([...rosterSelectable]);
    }
  };

  const validateFormData = () => {
    const errors = GroupRosterValidator.validate(formData);
    if (
      previewData.some(
        (groupRoster) =>
          groupRoster.roster?.email?.toLowerCase() ===
            formData.email.toLowerCase() &&
          groupRoster.roster.name?.toLowerCase() === formData.name.toLowerCase()
      )
    ) {
      errors.push("User already added to the preview");
    }
    if (
      seasonRoster.find(
        (roster) =>
          roster.email?.toLowerCase() === formData.email.toLowerCase() &&
          roster.title.toLowerCase() === formData.name.toLowerCase()
      )
    ) {
      errors.push("User already added to roster");
    }
    if (errors.length === 0) {
      setFormError(undefined);
      return true;
    }
    setFormError(errors.join(" "));
    return false;
  };

  const batchTextValueToGroupRoster = (batchText: string) => {
    const rows = batchText.split("\n");
    const reducedRows = rows.reduce((acc, row) => {
      if (!row.trim()) return acc; // skip empty row
      const [name, email] = row.trim().split(/[\t]/);
      const rosterGroupInput = {
        name: name ? name.trim() : "",
        email: email ? email.trim() : "",
      };

      const errors = GroupRosterValidator.validate(rosterGroupInput);
      if (!errors.length) {
        acc.push({
          roster: rosterGroupInput,
          isChecked: false,
        });
      }
      return acc;
    }, [] as GroupRosterChecked[]);
    return reducedRows;
  };
  const handleExistingSearch = () => {
    if (existingSearchText === "") {
      rostersQuery();
    } else if (existingSearchText.length < 3) {
      Toast?.setToastProps({
        message: "Minimum 3 letters in search text",
        type: "danger",
      });
      Toast?.toggleToast();
    } else {
      rostersQuery({
        variables: {
          where: {
            nameIncludes: existingSearchText,
          },
        },
      });
    }
  };

  return (
    <div className="modal-card">
      {previewData.length > 0 && (
        <>
          <p className="text-base font-medium">Selected Participants</p>
          <div
            ref={previewListElm}
            className="lg:flex lg:overflow-x-auto lg:pb-4"
          >
            {previewData.map((player, idx) => {
              return (
                <div
                  key={idx}
                  className="flex rounded-lg border border-gray-200 bg-gray-50 mt-4 px-4 py-3 lg:mr-4"
                >
                  <div className="mr-auto">
                    <p className="whitespace-nowrap font-semibold">
                      {player.roster?.name}
                    </p>
                    <p className="whitespace-nowrap text-sm mt-0.5">
                      {player.roster?.email}
                    </p>
                  </div>
                  <SnapIcon
                    className="cursor-pointer ml-2"
                    icon="trash-solid"
                    color="gray"
                    onClick={() => {
                      previewData.splice(idx, 1);
                      setPreviewData([...previewData]);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <Divider isVisibleOnMobile className="mb-4" />
        </>
      )}
      <p className="text-base font-medium">
        Enter participant name and parent email address below.
      </p>
      <form
        className="lg:grid grid-cols-3 mt-4"
        onSubmit={(e) => {
          e.preventDefault();
          if (validateFormData()) {
            setFormData({
              name: "",
              email: "",
            });
            setPreviewData([
              ...previewData,
              {
                roster: { name: formData.name, email: formData.email },
                isChecked: false,
              },
            ]);
          }
        }}
      >
        <SnapInput
          _id={"participant_name_input"}
          className="mr-6"
          label="Participant Name"
          value={formData.name || ""}
          onBlur={(e) => {
            setFormData({ ...formData, name: e.target.value });
          }}
        />

        <SnapInput
          _id={"parent_email_input"}
          className="mr-6"
          label="Parent Email"
          value={formData.email || ""}
          onBlur={(e) => {
            setFormData({ ...formData, email: e.target.value });
          }}
        />
        <button
          type={"submit"}
          hidden={true}
          aria-hidden={true}
          className={"fake-submit-button hidden"}
        />
        <SnapButton
          variant="primary"
          className={"lg:mt-6 mt-4 lg:w-[70%]"}
          fullWidth
          buttonType={"submit"}
          onSubmitBtn={true}
        >
          Add a New Participant
        </SnapButton>
      </form>
      {formError && (
        <p className="text-base font-medium text-red-500 mt-2">{formError}</p>
      )}
      <div className="border border-gray-200 mt-4 rounded-xl px-4">
        <button
          tabIndex={-1}
          className="flex relative hover:bg-gray-50 rounded-2xl -mx-4 p-4"
          style={{ width: "calc(100% + 32px)" }}
          onClick={() =>
            setIsSelectFromExistingExpanded(!isSelectFromExistingExpanded)
          }
        >
          <span className="mr-auto text-lg font-medium pr-8">
            Select from Existing
          </span>
          <SnapIcon
            icon={isSelectFromExistingExpanded ? "minus-solid" : "plus-solid"}
            className="self-center absolute right-4"
            size="md"
            color="#64748B"
          />
        </button>
        {isSelectFromExistingExpanded && (
          <>
            <div className="flex mt-4">
              <SnapInput
                _id="input-with-button"
                _type="text"
                input-btn="Search"
                input-btn-variant="secondary"
                className="lg:w-[40%] w-full"
                onBlur={(e) => {
                  setExistingSearchText(e.target.value);
                }}
                onInput-btn-click={(_) => handleExistingSearch()}
              />
            </div>
            {Display?.isDesktop ? (
              <AddParticipantTable
                list={rosterSelectable}
                isLoading={loading}
                onRosterSelect={onGroupRosterSelect}
              />
            ) : (
              <AddParticipantCard
                list={rosterSelectable}
                isLoading={loading}
                onRosterSelect={onGroupRosterSelect}
              />
            )}
            <div className="flex justify-end mb-4">
              <SnapButton
                variant="primary"
                onClick={() => {
                  const checkedRosterList = rosterSelectable.filter(
                    (x) => x.isChecked
                  );
                  addPreviewData(checkedRosterList);
                }}
              >
                Add To List
              </SnapButton>
            </div>
          </>
        )}
      </div>
      <div className="border border-gray-200 mt-4 rounded-xl px-4">
        <button
          tabIndex={-1}
          className="flex relative hover:bg-gray-50 rounded-2xl -mx-4 p-4"
          style={{ width: "calc(100% + 32px)" }}
          onClick={() => setIsBulkUploadFormExpanded(!isBulkUploadFormExpanded)}
        >
          <span className="mr-auto text-lg font-medium pr-8">
            Bulk Upload Form
          </span>
          <SnapIcon
            icon={isBulkUploadFormExpanded ? "minus-solid" : "plus-solid"}
            className="self-center absolute right-4"
            size="md"
            color="#64748B"
          />
        </button>
        {isBulkUploadFormExpanded && (
          <>
            <p className="lg:mt-2 mt-4 mb-2 text-base font-medium text-gray-500">
              Copy participant names and email addresses from a spreadsheet and
              paste them here for bulk upload. Participants names should be in
              the first one or two columns and the parent email address should
              be in the last column. There should be no other information pasted
              in here.
            </p>
            <SnapLink
              class="mt-0 mb-2"
              href="https://snap-mobile.zendesk.com/hc/en-us/articles/21145676466971"
              sr-only="srOnly"
            >
              Show me how it works!
            </SnapLink>
            <SnapInput
              _id={""}
              textarea
              onSnap-input-change={(e) => {
                setParticipantBatchValues(e.detail.target.value);
              }}
            />
            <div className="flex justify-end my-4">
              <SnapButton
                variant="primary"
                value={participantBatchValues}
                onClick={() => {
                  const newGroupRosters = batchTextValueToGroupRoster(
                    participantBatchValues
                  );
                  if (addPreviewData(newGroupRosters)) {
                    focusToPreviewList();
                  } else {
                    console.log("No new group roster were added!");
                  }
                }}
              >
                Add To List
              </SnapButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AddParticipant;
