import { useMutation, useQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import UserContext from "context/user-context";
import {
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  usePaymentsApiCustomerMutation,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import useToast from "hooks/use-toast";
import { useContext, useEffect, useState } from "react";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import LinkBank from "shared-components/banking/link-bank";
import DataCard from "shared-components/data-card";
import Divider from "shared-components/divider";
import CustomModal, { BtnState } from "shared-components/modal";
import {
  calcTotalPlusFee,
  defaultSelectedPaymentOption,
} from "shared-components/modal/make-payment/make-payment-helper";
import { OffSessionPaymentComponent } from "shared-components/payment-component/off-session-payment-component";
import { SnapCheckboxButton, SnapSelectMenu, SnapTable } from "suit";
import {
  GET_GROUP,
  GET_PARENT_INVOICES_CHANGE_PAYMENT,
} from "graphql/queries/group";
import VerticalLabelValue, {
  VerticalValueStyle,
} from "shared-components/vertical-label-value";
import cuid from "cuid";
import {
  GET_GUARDIAN_HIGHLIGHT_2,
  USER_BANK_ACCOUNTS,
} from "graphql/queries/user";
import { SelectedSpendGuardianComingSoonInvoice } from "types/invoice";
import { CHANGE_INVOICE_PAYMENT_METHOD } from "graphql/mutations/invoice";
import { extractBankLabel } from "helpers/banking";

type ViewUnauthorizedInvoicesProps = {
  isOpen: boolean;
  toggle: () => void;
  successToastToggle: () => void;
  data: SelectedSpendGuardianComingSoonInvoice[];
  setCurrentUnauthorizedInvoice: React.Dispatch<
    React.SetStateAction<SelectedSpendGuardianComingSoonInvoice[]>
  >;
};

function ViewUnauthorizedInvoicesModal({
  isOpen,
  toggle,
  successToastToggle,
  data,
  setCurrentUnauthorizedInvoice,
}: ViewUnauthorizedInvoicesProps) {
  const activeGroup = useContext(GroupContext)?.activeGroup;
  const activeUser = useContext(UserContext);
  const [bankAccounts, setBankAccounts] = useState<SpendBankAccount[]>([]);
  const [accountStatus, setAccountStatus] = useState("");
  const [paymentMethods, setPaymentMethods] = useState<
    PaymentsApiCustomerPaymentMethod[]
  >([]);
  const [groupAccountId, setGroupAccountId] = useState("");
  const { loading: loadingGroup, data: groupData } = useQuery(GET_GROUP, {
    skip: !isOpen,
  });
  const { data: accountsData } = useQuery(USER_BANK_ACCOUNTS, {
    skip: !activeUser?._session?.userId || !isOpen,
  });
  const [
    getPaymentMethods,
    { loading: loadingPaymentMethod, data: paymentMethodData },
  ] = usePaymentsApiCustomerMutation();

  useEffect(() => {
    getPaymentMethods();
  }, [activeUser, getPaymentMethods]);

  useEffect(() => {
    if (paymentMethodData && paymentMethodData.paymentsApiCustomer) {
      setPaymentMethods(
        paymentMethodData.paymentsApiCustomer
          .paymentMethods as PaymentsApiCustomerPaymentMethod[]
      );
    }
  }, [loadingPaymentMethod, paymentMethodData]);
  useEffect(() => {
    if (accountsData?.spendUserBankAccounts) {
      const tempAccounts = [
        ...accountsData.spendUserBankAccounts.externalAccounts,
      ];
      setAccountStatus(accountsData?.spendUserBankAccounts.status);
      setBankAccounts(tempAccounts);
    }
  }, [accountsData]);
  useEffect(() => {
    if (groupData && groupData.spendGroup) {
      setGroupAccountId(groupData.spendGroup.accountId);
    }
  }, [loadingGroup, groupData]);
  const paymentMethodCreate = () => {
    // setWidgetOpen(false);
    getPaymentMethods();
  };
  const extPayId = cuid();
  const { isToastOpen, ...toast } = useToast();
  const [paymentOptions, setPaymentOptions] = useState<SnapSelectMenuOption[]>(
    defaultSelectedPaymentOption
  );
  const [selectedPaymentTypeOption, setSelectedPaymentTypeOption] = useState<
    `Pay by: ${"Card" | "Bank"}` | undefined
  >();
  const [authorizeBtnState, setAuthorizeBtnState] = useState<BtnState>(
    BtnState.DISABLED
  );
  const [authorizeCheckBoxIsChecked, setAuthorizeCheckBoxIsChecked] =
    useState(false);
  useEffect(() => {
    if (
      authorizeCheckBoxIsChecked &&
      selectedPaymentTypeOption &&
      data.find((invoice) => invoice.selected === true) &&
      ((selectedPaymentTypeOption === "Pay by: Card" &&
        paymentMethods.length) ||
        (selectedPaymentTypeOption === "Pay by: Bank" && bankAccounts.length))
    ) {
      setAuthorizeBtnState(BtnState.BASE);
    }
    if (
      !authorizeCheckBoxIsChecked ||
      !selectedPaymentTypeOption ||
      !data.find((invoice) => invoice.selected === true) ||
      (selectedPaymentTypeOption === "Pay by: Card" &&
        !paymentMethods.length) ||
      (selectedPaymentTypeOption === "Pay by: Bank" && !bankAccounts.length)
    ) {
      setAuthorizeBtnState(BtnState.DISABLED);
    }
  }, [
    selectedPaymentTypeOption,
    authorizeCheckBoxIsChecked,
    data,
    paymentMethods,
    bankAccounts,
  ]);
  const [plaidConfig, setPlaidConfig] = useState<PlaidLinkOptions>({
    onSuccess: (public_token, metadata) => {},
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: "",
  });
  const { open, ready } = usePlaidLink(plaidConfig);
  const openPlaid = () => {
    if (ready) {
      open();
    }
  };
  const [changePaymentMethod] = useMutation(CHANGE_INVOICE_PAYMENT_METHOD, {
    refetchQueries: [
      {
        query: GET_PARENT_INVOICES_CHANGE_PAYMENT,
        variables: {
          filterBy: "memberId",
          filterValue: activeUser?._session?.userId,
        },
      },
      {
        query: GET_GUARDIAN_HIGHLIGHT_2,
      },
    ],
  });

  const handleChangeInvoicePayment = () => {
    let invoicesIds = data
      .filter((invoice) => invoice.selected === true)
      .map((invoice) => invoice.invoiceId);
    let paymentData = {
      paymentMethodId: "",
      paymentMethodSource: "",
    };
    if (selectedPaymentTypeOption === "Pay by: Bank") {
      paymentData = {
        paymentMethodId: bankAccounts.at(0)?.accountId ?? "",
        paymentMethodSource: "ACH",
      };
    } else {
      paymentData = {
        paymentMethodId: paymentMethods.at(0)?.id ?? "",
        paymentMethodSource: "CARD",
      };
    }
    changePaymentMethod({
      variables: {
        input: {
          ids: invoicesIds,
          paymentMethodId: paymentData.paymentMethodId,
          paymentMethodSource: paymentData.paymentMethodSource,
          isAutoPayAuthorized: true,
        },
      },
    });
    successToastToggle();
    HandleReset();
  };

  const HandleReset = () => {
    toggle();
    setAuthorizeCheckBoxIsChecked(false);
    setSelectedPaymentTypeOption(undefined);
    setPaymentOptions(defaultSelectedPaymentOption);
    let selectAllInvoices = data?.map((invoice) => {
      return {
        ...invoice,
        selected: false,
      };
    });
    setCurrentUnauthorizedInvoice(selectAllInvoices);
  };

  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const HandleSelectAll = (isChecked: boolean) => {
    let selectAllInvoices = data?.map((invoice) => {
      return {
        ...invoice,
        selected: isChecked,
      };
    });
    setCurrentUnauthorizedInvoice(selectAllInvoices);
  };
  const HandleSelect = (isChecked: boolean, idx: number) => {
    const tempSelectedInvoices = [...data];
    let selectedInvoice = {
      ...tempSelectedInvoices[idx],
      selected: isChecked,
    };

    tempSelectedInvoices?.splice(idx, 1, selectedInvoice);

    setCurrentUnauthorizedInvoice(tempSelectedInvoices);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Invoices for Approval"}
      customStyle={"lg:w-[974px] lg:overflow-y-scroll"}
      btn1={{
        text: "Authorize",
        btnStyle: "primary",
        btnState: authorizeBtnState,
        onClick: () => handleChangeInvoicePayment(),
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: HandleReset,
      }}
    >
      <div className="modal-card">
        <div>
          <p className="font-semibold text-lg">
            Invoices to Review and Approve
          </p>
          <p className="text-sm mt-1">
            Please review and approve these new or updated invoices.
          </p>
          <div className="hidden lg:flex">
            <SnapTable>
              <table className="ui table table-fixed">
                <thead>
                  <tr>
                    <th className="w-10">
                      <SnapCheckboxButton
                        onClick={(e) => {
                          setIsAllSelected(e.currentTarget.checked);
                          HandleSelectAll(e.currentTarget.checked);
                        }}
                        checked={isAllSelected}
                      />
                    </th>
                    <th className="text-center">Description</th>
                    <th className="text-center">Participant</th>
                    <th className="text-center">Group</th>
                    <th className="text-center">Season</th>
                    <th className="text-center">Billing Date</th>
                    <th className="text-center">Pay By Bank</th>
                    <th className="text-center">Pay By Card</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((invoice, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="w-10">
                          <SnapCheckboxButton
                            onClick={(e) => {
                              HandleSelect(e.currentTarget.checked, idx);
                            }}
                            checked={invoice.selected}
                          />
                        </td>
                        <td className="py-4 text-center">
                          {invoice?.invoiceDescription}
                        </td>
                        <td className="text-center">{invoice?.rosterName}</td>
                        <td className="text-center">{invoice?.groupName}</td>
                        <td className="text-center">{invoice?.seasonName}</td>
                        <td className="text-center">
                          {FormatDate(
                            invoice?.invoiceDueDate!,
                            DateFormatSupported.Words
                          )}
                        </td>
                        <td className="text-center">
                          {FormatMoney(
                            calcTotalPlusFee(
                              invoice.invoiceAmountDue ?? 0,
                              activeGroup?.organizationFees?.achPercent ?? 0,
                              activeGroup?.organizationFees?.achBaseFee ?? 0
                            )
                          )}
                        </td>
                        <td className="text-center">
                          {FormatMoney(
                            calcTotalPlusFee(
                              invoice?.invoiceAmountDue ?? 0,
                              activeGroup?.organizationFees?.cardPercent ?? 0,
                              activeGroup?.organizationFees?.cardBaseFee ?? 0
                            )
                          )}
                        </td>
                        {/* <td className="text-center">
                          {invoice?.invoiceId ? "Required" : "Not Required"}
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </SnapTable>
          </div>
          <div className="mt-4">
            {data?.map((invoice, idx) => {
              return (
                <DataCard
                  className="flex lg:hidden"
                  key={idx}
                  title={invoice?.invoiceDescription || "Description"}
                  kvLeft={[]}
                  kvRight={[
                    {
                      key: "Billing Date",
                      value: `${FormatDate(
                        invoice?.invoiceDueDate!,
                        DateFormatSupported.Words
                      )}`,
                    },
                    {
                      key: "Pay By Bank",
                      value: `${FormatMoney(
                        calcTotalPlusFee(
                          invoice?.invoiceAmountDue ?? 0,
                          activeGroup?.organizationFees?.achPercent ?? 0,
                          activeGroup?.organizationFees?.achBaseFee ?? 0
                        )
                      )}`,
                    },
                    {
                      key: "Pay By Card",
                      value: `${FormatMoney(
                        calcTotalPlusFee(
                          invoice?.invoiceAmountDue ?? 0,
                          activeGroup?.organizationFees?.cardPercent ?? 0,
                          activeGroup?.organizationFees?.cardBaseFee ?? 0
                        )
                      )}`,
                    },
                  ]}
                  action={0}
                  hasCheckbox
                  isChecked={invoice.selected}
                  checkboxAction={(e) => {
                    HandleSelect(e.currentTarget.checked, idx);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="mt-3">
          <p className="mt-6 mb-3 font-semibold text-lg">Payment Method</p>
          <SnapSelectMenu
            placeholder="- Select Payment Method -"
            selectMenuOptions={paymentOptions}
            onSnap-select-menu-updated={(e) => {
              const op = e.detail.find((option) => option.selected)?.name;
              if (!op) {
                setSelectedPaymentTypeOption(undefined);
                setPaymentOptions(e.detail);
              } else {
                setSelectedPaymentTypeOption(
                  op === "Pay by: Card" ? "Pay by: Card" : "Pay by: Bank"
                );
                setPaymentOptions(e.detail);
              }
            }}
          />
          <div className="mt-3">
            {selectedPaymentTypeOption === "Pay by: Bank" ? (
              <>
                {bankAccounts.length !== 0 ? (
                  <VerticalLabelValue
                    label="Linked Bank"
                    labelStyle="text-sm font-normal"
                    value={extractBankLabel(bankAccounts.at(0))}
                    valueStyle={VerticalValueStyle.ActionLabel}
                    // actionLabel="Change"
                    //labelAction={() => console.log("first")}
                    customContainerStyle={
                      "border-none lg:text-start flex flex-col"
                    }
                    actionLabelClassname="flex-col"
                  />
                ) : (
                  <LinkBank
                    openPlaid={openPlaid}
                    setPlaidConfig={setPlaidConfig}
                    toast={toast}
                    type={"user"}
                    labelText={
                      accountStatus === "pending_manual_verification"
                        ? "Verify Account"
                        : "Add Account"
                    }
                  />
                )}
              </>
            ) : selectedPaymentTypeOption === "Pay by: Card" ? (
              <>
                {paymentMethods.length ? (
                  paymentMethods.map((method, idx) => {
                    return (
                      <div key={`${method.identifier} ${idx}`} className="flex">
                        <p>{method.identifier}</p>
                      </div>
                    );
                  })
                ) : (
                  <OffSessionPaymentComponent
                    returnUrl={`${document.baseURI}&externalId=${extPayId}`}
                    payerDetails={{
                      id: activeUser?.getUserId() ?? "",
                      name: activeUser?.getName() ?? "",
                      email: activeUser?.getEmail() ?? "",
                    }}
                    destination={groupAccountId}
                    externalPaymentId={extPayId}
                    paymentMethodCreate={paymentMethodCreate}
                  />
                )}
              </>
            ) : null}
          </div>
        </div>
        <Divider isVisibleOnMobile className="mb-6" />
        <SnapCheckboxButton
          onSnap-checkbox-updated={(e) => {
            if (e.detail.checked) {
              setAuthorizeCheckBoxIsChecked(true);
            }
            if (!e.detail.checked) {
              setAuthorizeCheckBoxIsChecked(false);
            }
          }}
          className="mb-2"
          label="I authorize Snap! to charge my payment method according to the above schedule."
        />
      </div>
    </CustomModal>
  );
}

export default ViewUnauthorizedInvoicesModal;
