import { SnapDropdownCustomEvent } from "@snap-mobile/snap-ui/dist/types/components";
import {
  DropdownItemSelected,
  DropdownMenuItem,
} from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import SplitIOContext from "context/splitio-context";
import { SpendSortInput } from "graphql/generated";
import { HandleAllCheckboxAction } from "helpers/all-checkbox-action";
import { useContextStrict } from "helpers/context-strict";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SpinnerContainer } from "shared-components/spinner";
import NoResults from "shared-components/table/no-results";
import SortIcon from "shared-components/table/sort-icon";
import { SnapCheckboxButton, SnapDropDown, SnapTable } from "suit";
import { InvitePlus } from "types/invite";

type TableProps = {
  teamUsers: InvitePlus[];
  getBadge: (status: string) => JSX.Element;
  handleCheckboxAction: (idx: number, data: InvitePlus) => void;
  handleRemoveUser: (idx: number) => void;
  setTeamUsers: (userArray: any) => void;
  menuItems: DropdownMenuItem[];
  isNameBoxChecked: boolean;
  setNameBoxChecked: (value: boolean) => void;
  sort: SpendSortInput | undefined;
  toggleSort: (sort: string) => void;
  changeRoleToggle: () => void;
  setMenuClickData: React.Dispatch<React.SetStateAction<InvitePlus | null>>;
  canUpdateGroupUser: boolean;
  setSelectedItems: React.Dispatch<React.SetStateAction<InvitePlus[]>>;
  selectedItems: InvitePlus[];
  loading: boolean;
  enableApproverToggle: () => void;
  disableApproverToggle: () => void;
};

function Table({
  teamUsers,
  getBadge,
  handleCheckboxAction,
  handleRemoveUser,
  setTeamUsers,
  menuItems,
  isNameBoxChecked,
  setNameBoxChecked,
  sort,
  toggleSort,
  changeRoleToggle,
  setMenuClickData,
  canUpdateGroupUser,
  setSelectedItems,
  selectedItems,
  loading,
  enableApproverToggle,
  disableApproverToggle,
}: TableProps) {
  const splits = useContextStrict(SplitIOContext);
  const [spe1288DualApproval, setSPE1288DualApproval] = useState(false);
  useEffect(() => {
    setSPE1288DualApproval(splits.isTreatmentOn("SPE-1288-Dual-Approval"));
  }, [splits]);

  const navigate = useNavigate();
  const Group = useContext(GroupContext);
  const navigateGroupLink = (groupId: string) => {
    if (Group && !Group.activeGroup) {
      const group = Group?.groups?.find((gr) => gr.id === groupId);
      Group.setAndStoreActiveGroup(group);
    }
    navigate("/groups/staff");
  };
  const menuAction = (
    e: SnapDropdownCustomEvent<DropdownItemSelected>,
    tUser: InvitePlus,
    idx: number
  ) => {
    setMenuClickData(tUser);
    let value = e.detail.value;
    if (value === "changeRole") {
      changeRoleToggle();
    } else if (value === "removePermissions") {
      handleRemoveUser(idx);
    } else if (value === "disableApprover") {
      disableApproverToggle();
    } else if (value === "enableApprover") {
      enableApproverToggle();
    }
  };
  return (
    <SnapTable>
      <table className="celled ui hidden lg:table mt-0">
        <thead>
          <tr>
            {canUpdateGroupUser && (
              <th className="w-2">
                <SnapCheckboxButton
                  checked={isNameBoxChecked}
                  onClick={(e) => {
                    setNameBoxChecked(e.currentTarget.checked);
                    HandleAllCheckboxAction(
                      isNameBoxChecked,
                      teamUsers,
                      setTeamUsers,
                      setSelectedItems,
                      selectedItems
                    );
                  }}
                />
              </th>
            )}
            <th>
              Name
              <SortIcon
                field={"name"}
                toggleSort={toggleSort}
                currentSort={sort}
              />
            </th>
            <th>
              Email
              <SortIcon
                field={"email"}
                toggleSort={toggleSort}
                currentSort={sort}
              />
            </th>
            <th>
              Role
              <SortIcon
                field={"type"}
                toggleSort={toggleSort}
                currentSort={sort}
              />
            </th>
            <th>Debit Card</th>
            <th>
              Group
              <SortIcon
                field={"group"}
                toggleSort={toggleSort}
                currentSort={sort}
              />
            </th>
            <th>
              Status
              <SortIcon
                field={"status"}
                toggleSort={toggleSort}
                currentSort={sort}
              />
            </th>
            <th>{/* Action */}</th>
          </tr>
        </thead>
        <tbody>
          <SpinnerContainer loading={loading} inTable={true} colSpan={8} />
          {!loading &&
            teamUsers.map((tUser, idx) => {
              const approverMenuItem: DropdownMenuItem = {
                name: `${tUser.isApprover ? "Disable" : "Enable"} Approver`,
                text: `${tUser.isApprover ? "Disable" : "Enable"} Approver`,
                value: `${tUser.isApprover ? "disable" : "enable"}Approver`,
              };

              const newMenuItems = [...menuItems, approverMenuItem];
              return (
                <tr key={tUser.id} className="border border-blue-600">
                  {canUpdateGroupUser && (
                    <td className="checkbox-cell">
                      <SnapCheckboxButton
                        checked={tUser.isChecked}
                        onClick={() => handleCheckboxAction(idx, tUser)}
                      />
                    </td>
                  )}
                  <td>{`${tUser.firstName} ${tUser.lastName}`}</td>
                  <td>{tUser.email}</td>
                  <td className="capitalize">
                    {tUser.type ? tUser.type.split("_").join(" ") : ""}
                  </td>
                  <td>
                    {tUser.debitCard === "ASSIGNED"
                      ? "Assigned"
                      : tUser.debitCard === "NOT_ASSIGNED"
                      ? "Unassigned"
                      : !tUser.debitCard
                      ? "Unassigned"
                      : tUser.debitCard}
                  </td>
                  <td>
                    {tUser.group?.id ? (
                      <a
                        href={"/groups/staff"}
                        onClick={(e) => {
                          navigateGroupLink(tUser.group?.id || "");
                        }}
                        className="cursor-pointer text-blue-600 hover:underline"
                      >
                        {tUser.group?.name || "N/A"}
                      </a>
                    ) : (
                      <p className="text-gray-500">N/A</p>
                    )}
                  </td>
                  <td className="py-4">{getBadge(tUser.status || "")}</td>
                  <td>
                    {(spe1288DualApproval
                      ? newMenuItems.length
                      : menuItems.length) > 0 && (
                      <SnapDropDown
                        minimal
                        options={spe1288DualApproval ? newMenuItems : menuItems}
                        onSnap-dropdown-item-selected={(e) => {
                          menuAction(e, tUser, idx);
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          {teamUsers.length === 0 && (
            <NoResults loading={!loading} inTable={true} colSpan={8} />
          )}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default Table;
